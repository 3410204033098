import React, {useEffect, useState} from "react";
import ContactInput from "../contact-input/contact-input";
import {styled} from "@mui/material/styles";
import {alpha, FormControl, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import {classes} from "./contact-input-select-styles";
import Typography from "@mui/material/Typography";

const StyledContactInputSelect = styled(Grid, {shouldForwardProp: (prop) => prop !== 'inverseColors'})
(({theme, inverseColors}) => ({
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        margin: 'auto'
    },
    [theme.breakpoints.down('md')]: {
        margin: 'auto'
    },
    [`& .${classes.inputLabelContainerAlign}`]: {
        [theme.breakpoints.down('lg')]: {
            textAlign: 'center',
            padding: '4px 12px 4px 12px',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
            padding: '12px',
        },
    },
    [`& .${classes.textFieldContainerAlign}`]: {
        [theme.breakpoints.down('lg')]: {
            textAlign: 'center',
            padding: '0px 12px 0px 12px',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
            padding: '12px 0px 12px 12px',
        },
    },
    [`& .${classes.inputLabel}`]: {
        color: inverseColors ? theme.palette.secondary.main : alpha(theme.palette.primary.background, 0.87),
        textAlign: 'right',
        position: 'relative',
        letterSpacing: '2px',
    },
}));

const ContactInputSelect = (props) => {
    const [selectedCity, setSelectedCity] = useState(props.options[0].value);
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        props.onChange({
            target: {id: props.id, value: "Wrocław"}
        });
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        const invalid = (props.validity?.required && !value) ||
            (props.validity?.pattern && !new RegExp(props.validity.pattern).test(value));
        setIsInvalid(invalid);
        setSelectedCity(value);

        if (value === "Wrocław") {
            props.onChange({
                target: {id: props.id, value: "Wrocław"}
            });
        } else {
            props.onChange({
                target: {id: props.id, value: ""}
            });
        }
    };

    return (
        <StyledContactInputSelect container
                                  item
                                  inverseColors={props.inverseColors}
        >
            <Grid item
                  xs={12} sm={12} md={3} lg={4} xl={4}
                  className={classes.inputLabelContainerAlign}
            >
                <Typography variant={"h3"} className={classes.inputLabel}>
                    {props.selectPlaceholder}
                </Typography>
            </Grid>
            <Grid item
                  xs={12} sm={12} md={9} lg={8} xl={8}
                  component={FormControl}
                  className={classes.textFieldContainerAlign}
            >
                <Select fullWidth
                        name={props.id}
                        id={props.id}
                        value={selectedCity}
                        onChange={handleChange}
                        label={props.inputPlaceholder}
                        variant="filled"
                        size="small"
                        error={isInvalid}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <ContactInput id={props.id}
                          inverseColors={props.inverseColors}
                          value={selectedCity === "Wrocław" ? "Wrocław" : props.value}
                          onChange={props.onChange}
                          label={props.label}
                          inputPlaceholder={props.inputPlaceholder}
                          validity={props.validity}
            />
        </StyledContactInputSelect>
    );
};

export default ContactInputSelect;
