import Typography from "@mui/material/Typography";
import {classes} from "./appointment-selection-line-styles";
import Timeline from "@mui/lab/Timeline";
import * as React from "react";
import styled from "@emotion/styled";
import TimeLine from "./appointment-timeline";

const StyledAppointmentSelectionLine = styled(Timeline)(({theme}) => ({
    position: "right",
    [`& .${classes.specialLink}`]: {
        color: theme.palette.primary.background,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.mainShadowed,
        },
    },
    [`& .${classes.actionsTitle}`]: {
        color: theme.palette.primary.background,
    },
    [`& .${classes.actionsTitleOutlined}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${classes.dayOfWeekTitle}`]: {
        width: "100%",
        textAlign: "center",
    },
}));

const hours = Array.from({length: 18}, (_, i) => i + 6);

const AppointmentSelectionLine = ({availabilityWithTimeSlot}) => {

    if (availabilityWithTimeSlot === undefined) {
        return (<div>loading</div>);
    }

    let date = new Date(availabilityWithTimeSlot.date);

    const isSlotAvailable = (hour: number): boolean => {
        const timeSlots: string[] = availabilityWithTimeSlot.timeSlots;

        if (!timeSlots || timeSlots.length === 0) {
            return false;
        } else {
            return timeSlots.some(timeSlot => {
                const concreteHour = timeSlot.split(':')[0];
                const formattedConcreteHour = parseInt(concreteHour, 10);
                return formattedConcreteHour === hour;
            });
        }
    };

    return (
        <StyledAppointmentSelectionLine>
            <Typography className={`${classes.dayOfWeekTitle}`} variant={"h3"}>
                {date.toLocaleDateString('pl-PL', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}
            </Typography>
            {hours.map((hour) => (
                <TimeLine key={hour}
                          hour={hour}
                          date={date}
                          isSlotAvailable={isSlotAvailable(hour)}
                />
            ))}
        </StyledAppointmentSelectionLine>
    )
}

export default AppointmentSelectionLine;
