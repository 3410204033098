import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import translate from '../../language/translate';
import ContactInfo from "./contact-info/contact-info";
import type {ContactDataValid} from "./shared/dto/contact-data-valid";
import {classes} from "./contact-styles";
import {classes as classesApp} from "../app-styles";
import {LocaleContext} from "../../context/locale-context/locale-context";
import {defaultLocale} from "../../context/locale-context/default-locale";
import ContactAddress from "./contact-address/contact-address";
import ContactPersonalData from "./contact-personal-data/contact-personal-data";
import {useContactApi} from "./api/contact-api";
import {AppContext} from "../../context/app-context/app-context";
import {ServiceOrderContext} from "../../context/service-order-context/service-order-context";
import {useNavigate} from "react-router-dom";
import ContactButton from "./contact-button/contact-button";
import ContactInputPrivacyPolicy from "./contact-input-privacy-policy/contact-input-privacy-policy";

export const StyledContact = styled(Box)(({theme}) => ({
    [`& .${classes.form}`]: {
        display: 'contents'
    },
}));

const Contact = ({extended, children}) => {

    const {sendEmail, createOrder} = useContactApi();
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;
    const {setInfo} = useContext(AppContext);
    const {serviceOrderData} = useContext(ServiceOrderContext);
    const navigate = useNavigate();
    const [isFormValid, setIsFormValid] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    const [contact: ContactDataValid, onContactChange] = useState({
        address: {
            city: {value: '', validity: {required: true, maxLength: 50}},
            street: {value: '', validity: {required: true, maxLength: 100}},
            house: {value: '', validity: {required: false, maxLength: 10}},
            houseNumber: {value: '', validity: {required: false, maxLength: 10}},
            postalCode: {value: '', validity: {required: true, pattern: "^\\d{2}-\\d{3}$"}},
        },
        personalData: {
            name: {value: '', validity: {required: true, maxLength: 100}},
            email: {value: '', validity: {required: true, pattern: "^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$"}},
            phoneNumber: {value: '', validity: {required: true, pattern: "^(\\+48)?\\s?\\d{3}\\s?\\d{3}\\s?\\d{3}$"}},
            message: {value: '', validity: {required: true, maxLength: 500}},
        }
    });

    useEffect(() => {
        const fieldsToValidate = extended
            ? [...Object.values(contact.address), ...Object.values(contact.personalData)]
            : Object.values(contact.personalData);

        const hasErrors = fieldsToValidate.some(field =>
            (field.validity.required && !field.value) ||
            (field.validity.pattern && !new RegExp(field.validity.pattern).test(field.value)) ||
            (field.validity.maxLength && field.value.length > field.validity.maxLength)
        );
        const allEmpty = fieldsToValidate.every(field => field.validity.required && !field.value);
        setIsFormValid(!hasErrors && !allEmpty);
    }, [contact, extended]);

    const transformToContactData = (validContact) => {
        return {
            address: Object.fromEntries(
                Object.entries(validContact.address).map(([key, {value}]) => [key, value])
            ),
            personalData: Object.fromEntries(
                Object.entries(validContact.personalData).map(([key, {value}]) => [key, value])
            )
        };
    };

    const submit = async (event) => {
        event.preventDefault();
        const contactData = transformToContactData(contact);
        if (extended) {
            await createOrder(contactData, serviceOrderData).then(() => setInfo(translatedContact.action.order.success));
        } else {
            await sendEmail(contactData).then(() => setInfo(translatedContact.action.email.success));
        }
        navigate('/');
    };

    const extendedSection = (extended) => {
        if (extended) {
            return (children)
        } else {
            return (
                <ContactInfo
                    label={translatedContact.label}
                    shortInfo={translatedContact.shortInfo}
                    info={translatedContact.info}
                />
            )
        }
    };

    return (
        <StyledContact id='contact'
                       className={classesApp.top}
        >
            <Box
                position={'relative'}
                flexDirection='row'
                display={'flex'}
                justifyContent='center'
            >
                <Grid container={true}
                      component={"form"}
                      noValidate
                      autoComplete="off"
                      onSubmit={submit}
                      justifyContent='center'
                >
                    <Grid container
                          item
                          xs={12} sm={12} md={extended ? 3 : 6} lg={extended ? 3 : 6} xl={extended ? 3 : 6}
                          direction={'column'}
                          justifyContent={'center'}
                    >
                        {extendedSection(extended)}
                    </Grid>
                    <Grid container
                          item
                          xs={12} sm={12} md={6} lg={6} xl={6}
                          justifyContent={extended ? 'flex-start' : 'center'}
                          alignItems={'center'}
                    >
                        {extended &&
                            <ContactAddress inverseColors={extended}
                                            contact={contact}
                                            onContactChange={onContactChange}
                            />
                        }
                        <ContactPersonalData inverseColors={extended}
                                             contact={contact}
                                             onContactChange={onContactChange}
                        />
                        <ContactButton id={'send-button'}
                                       label={translatedContact.form.sendButton.label}
                                       disabled={!isFormValid || !privacyPolicyAccepted}
                        />
                        <ContactInputPrivacyPolicy id={'privacy-policy-accepted'}
                                                   privacyPolicyAccepted={privacyPolicyAccepted}
                                                   setPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
                                                   extended={extended}
                        />
                    </Grid>
                </Grid>
            </Box>
        </StyledContact>
    );
}

export default Contact;
