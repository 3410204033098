import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {Box, Typography} from "@mui/material";
import ConstructionElementsContentTable
    from "./construction-elements-content-table/construction-elements-content-table";
import ConstructionElementsWorkDescription
    from "./construction-elements-work-description/construction-elements-work-description";
import ConstructionElementsContentDescription
    from "../construction-elements-content-description/construction-elements-content-description";
import ConstructionElementsServiceOffer
    from "./construction-elements-service-offer/construction-elements-service-offer";

const StyledConstructionElementsContent = styled(Box)(({theme}) => ({}));

function ConstructionElementsContent({actionButtons = {}, selectedContent}) {
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translatedOffer = translation.offer;

    const renderContent = () => {
        if (!actionButtons || Object.keys(actionButtons).length === 0) {
            return (
                <Typography variant={"h2"}>
                    {translatedOffer.actions.noAction}
                </Typography>
            );
        }

        switch (selectedContent) {
            case actionButtons.HEAT_PUMP?.action:
                return (
                    <>
                        <ConstructionElementsContentDescription/>
                        <ConstructionElementsContentTable/>
                    </>
                );
            case actionButtons.SOLAR_PANELS.action:
                return (<ConstructionElementsWorkDescription
                    selectedContent={translatedOffer.actions.content.elements[actionButtons.SOLAR_PANELS.action]}/>);
            case actionButtons.SERVICE_24_7.action:
                return (<ConstructionElementsServiceOffer/>);
            case actionButtons.UNDERFLOOR_HEATING.action:
                return (<ConstructionElementsWorkDescription
                    selectedContent={translatedOffer.actions.content.elements[actionButtons.UNDERFLOOR_HEATING.action]}/>);
            default:
                return <Typography>No content.</Typography>;
        }
    };

    return <StyledConstructionElementsContent>{renderContent()}</StyledConstructionElementsContent>;
}

export default ConstructionElementsContent;
