const PREFIX = 'contact-button';

export const classes = {
    top: `${PREFIX}-top`,
    inputButtonMargin: `${PREFIX}-input-button-margin`,
    inputGridSmallResolutionMargin: `${PREFIX}-input-grid-small-resolution-margin`,
    inputLabel: `${PREFIX}-input-label`,
    inputLabelEnabled: `${PREFIX}-input-label-enabled`,
    inputLabelDisabled: `${PREFIX}-input-label-disabled`,
};
