import {Button} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import LoginIcon from '@mui/icons-material/Login';
import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {LocaleContext} from "../../../../context/locale-context/locale-context";
import translate from "../../../../language/translate";
import {defaultLocale} from "../../../../context/locale-context/default-locale";

const StyledHomeNavigationProfileButton = styled(Button)(({theme}) => ({
    width: "max-content"
}));

const HomeNavigationProfileButton = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedHome = translation.home;

    const [buttonVariant, setButtonVariant] = useState({
        label: translatedHome.action.logIn,
        icon: <AccountCircle/>,
        action: () => auth.signinRedirect, // Ensure this is a function reference
    });

    useEffect(() => {
        console.log("calculate login button");
        if (auth.isAuthenticated) {
            setButtonVariant({
                label: translatedHome.action.myProfile,
                icon: <AccountCircle/>,
                action: () => () => navigate('/oauth2/callback'),
            });
        } else {
            setButtonVariant({
                label: translatedHome.action.logIn,
                icon: <LoginIcon/>,
                action: () => () => auth.signinRedirect(),
            });
        }
    }, [auth.isAuthenticated, auth.signinRedirect, navigate]);

    return (
        <StyledHomeNavigationProfileButton
            variant="contained"
            size="medium"
            startIcon={buttonVariant.icon}
            onClick={buttonVariant.action()}
        >
            {buttonVariant.label}
        </StyledHomeNavigationProfileButton>
    );
};

export default HomeNavigationProfileButton;
