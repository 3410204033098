import type {ContactData} from '../shared/dto/contact-data';
import {ServiceOrderData} from '../../../context/service-order-context/data/service-order-data';
import {useApi} from "../../../context/api-context/api-context";

export const useContactApi = () => {
    const api = useApi();

    const sendEmail = async (contact: ContactData) => {
        try {
            const contactData = contact.personalData;
            const payload = {
                ...contactData,
                from: contactData.email,
                text: contactData.message,
            };
            await api.post('/mail', payload);
            console.log('Email sent successfully');
            return {success: true};
        } catch (error) {
            console.error('Error sending email:', error);
            return {success: false, error};
        }
    };

    const createOrder = async (contact: ContactData, serviceOrderData: ServiceOrderData) => {
        try {
            const personalData = contact.personalData;
            const payload = {
                serviceOrderDetailsDto: serviceOrderData.serviceOrderDetails,
                serviceOrderTypeDto: serviceOrderData.serviceOrderType,
                serviceOrderSubtypeDto: serviceOrderData.serviceOrderSubtype,
                mailDto: {
                    ...personalData,
                    from: personalData.email,
                    text: personalData.message,
                },
                addressDto: contact.address,
            };
            await api.post('/order', payload);
            console.log('Order created successfully');
            return {success: true};
        } catch (error) {
            console.error('Error creating an order:', error);
            return {success: false, error};
        }
    };

    return {sendEmail, createOrder};
};
