import {Drawer, List, ListItem, ListItemText} from "@mui/material";
import React from "react";
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";

const HomeNavigationMenuDrawer = ({navLinks, drawerOpen, toggleDrawer}) => {
    const auth = useAuth();
    const navigate = useNavigate();

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List>
                {navLinks.map((link, index) => (
                    <ListItem button component="a" key={index} href={link.href} onClick={toggleDrawer(false)}>
                        <ListItemText primary={link.label}/>
                    </ListItem>
                ))}
                {auth.isAuthenticated ? (
                    <ListItem
                        button
                        onClick={() => {
                            navigate('/oauth2/callback');
                            toggleDrawer(false);
                        }}
                    >
                        <ListItemText primary="Mój profil"/>
                    </ListItem>
                ) : (
                    <ListItem
                        button
                        onClick={() => {
                            auth.signinRedirect();
                            toggleDrawer(false);
                        }}
                    >
                        <ListItemText primary="Zaloguj się"/>
                    </ListItem>
                )}
            </List>
        </Drawer>
    )
};

export default HomeNavigationMenuDrawer;
