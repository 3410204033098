import React from 'react';
import {useAuth} from "react-oidc-context";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import type {TechnicianPriceStrategyExtended} from "../../shared/dto/technician-price-strategy-extended";

const StyledTechnicianProfilePriceStrategyTable = styled(Box)(({theme}) => ({}));

const TechnicianProfilePriceStrategyTable = ({technicianPriceStrategies}: {
    technicianPriceStrategies: TechnicianPriceStrategyExtended[]
}) => {
    const auth = useAuth();

    if (auth.isAuthenticated) {
        return (
            <StyledTechnicianProfilePriceStrategyTable>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Translation</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Transport Price</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {technicianPriceStrategies.map((strategy) => (
                                <TableRow key={strategy.id}>
                                    <TableCell>{strategy.serviceOrderSubtypeExtendedDto.translation}</TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={strategy.price}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={strategy.priceTransport}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            defaultValue={strategy.currency}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color="primary">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledTechnicianProfilePriceStrategyTable>
        );
    }

    return (
        <Typography variant="body1" color="textSecondary">
            User not authenticated. Please log in to view the technician profile.
        </Typography>
    );
};

export default TechnicianProfilePriceStrategyTable;
