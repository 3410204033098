import React from 'react';
import {styled} from "@mui/material/styles";

const StyledGalleryModalImage = styled('img')((theme) => ({
    '@media screen and (max-width: 1279px)': {
        width: 'auto',
        height: '100%',
        'object-fit': 'cover',
        'object-position': 'bottom',
    },
    'max-width': '100%',
    'object-fit': 'cover',
    'object-position': 'top',
}));

const GalleryModalImage = (props) => {
    return (
        <StyledGalleryModalImage src={props.src} alt={props.alt}/>
    )
}

export default GalleryModalImage;
