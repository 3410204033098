import React, {useContext, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {CircularProgress, Grid, Paper, Typography} from '@mui/material';
import {AcUnit, Build, Construction, ElectricalServices, Plumbing, WbSunny} from '@mui/icons-material';
import {useOrderApi} from '../order-managment/api/order-api';
import {useNavigate} from 'react-router-dom';
import {ServiceOrderContext} from '../../context/service-order-context/service-order-context';
import {classes} from './home-styles';
import type {OrderTypeBlockExtendedProps} from "../order-managment/order-type-block/order-type-block-props";
import type {ServiceOrderType} from "../order-managment/shared/dto/service-order-type";
import {LocaleContext} from "../../context/locale-context/locale-context";
import translate from "../../language/translate";
import {defaultLocale} from "../../context/locale-context/default-locale";
import HomeNavigation from "./home-navigation/home-navigation";

const StyledHome = styled("article")(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary.background,
    margin: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    [`& .${classes.main}`]: {
        width: '100%',
        flexGrow: 1,
        boxSizing: 'border-box',
    },
    [`& .${classes.logoWrapper}`]: {
        color: 'white',
        display: 'contents',
        justifyContent: 'center',
        margin: '0 auto',
        padding: '0 8vw',
        [theme.breakpoints.up('md')]: {
            height: '50vh',
        },
    },
    [`& .${classes.logoWrapperText}`]: {
        padding: '16px 8vw',
        textAlign: 'center',
        lineHeight: '1.6rem',
        color: theme.palette.primary.background,
    },
    [`& .${classes.logo}`]: {
        width: '100%',
        maxWidth: '200px',
        maxHeight: '200px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '400px',
            maxHeight: '400px',
        },
        height: 'auto',
    },
    [`& .${classes.services}`]: {
        flexGrow: 1,
        height: '30%',
        padding: theme.spacing(2),
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },
    [`& .${classes.serviceItem}`]: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: theme.palette.primary.background,
        borderRadius: '8px',
        padding: theme.spacing(2.5),
        border: `2px solid ${theme.palette.secondary.main}`,
        transition: 'transform 0.3s, color 0.3s, background-color 0.3s',
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: "pointer",
            transform: 'scale(1.05)',
            color: theme.palette.primary.background,
            backgroundColor: theme.palette.primary.mainShadowed,
            [`& .${classes.icon}`]: {
                color: theme.palette.primary.background,
            },
            [`& .${classes.serviceItemText}`]: {
                color: theme.palette.primary.background,
            },
        },
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
    },
    [`& .${classes.serviceItemText}`]: {},
    [`& .${classes.icon}`]: {
        fontSize: '1.8em',
        color: theme.palette.secondary.main,
    }
}));

const serviceIcon = {
    PLUMBING: {
        ariaLabel: "Hydraulika",
        icon: <Plumbing className={classes.icon}/>,
    },
    UNBLOCKING_PIPE: {
        ariaLabel: "Udrażnianie rur",
        icon: <Construction className={classes.icon}/>,
    },
    SERVICE_ELECTRICAL: {
        ariaLabel: "Usługi elektryczne",
        icon: <ElectricalServices className={classes.icon}/>,
    },
    PLUMBING_SERVICE: {
        ariaLabel: "Usługi hydrauliczne",
        icon: <Build className={classes.icon}/>,
    },
    SOLAR_COLLECTOR: {
        ariaLabel: "Kolektory słoneczne",
        icon: <WbSunny aria-label={"Kolektory słoneczne"} className={classes.icon}/>,
    },
    HEAT_PUMP: {
        ariaLabel: "Pompa ciepła",
        icon: <AcUnit aria-label={"Pompa ciepła"} className={classes.icon}/>,
    },
};

const Home = () => {
    const {fetchAllServiceOrderTypes} = useOrderApi();
    const [serviceOrderTypes, setServiceOrderTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const {setServiceOrderType, setServiceOrderSubtype} = useContext(ServiceOrderContext);
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedHome = translation.home;

    useEffect(() => {
        setLoading(true);
        fetchAllServiceOrderTypes().then(result => {
            const updatedTypes = result.map(type => ({
                ...type,
                appointmentUrl: `/order/${encodeURIComponent(type.serviceOrderTypeDto.name)}/subtype/list`
            }));
            setServiceOrderTypes(updatedTypes);
            setLoading(false);
        });
    }, []);

    const navigateToNextComponent = (orderTypeBlockExtendedProps: OrderTypeBlockExtendedProps) => {
        const {id, name, translationKey, image, actionUrl, typeIdentification} = orderTypeBlockExtendedProps;
        const serviceOrderTypeOrSubtype: ServiceOrderType = {id, name, translationKey, image};
        if (typeIdentification === 'TYPE') {
            setServiceOrderType(serviceOrderTypeOrSubtype);
        } else {
            setServiceOrderSubtype(serviceOrderTypeOrSubtype);
        }
        navigate(actionUrl);
    };

    if (loading) {
        return (
            <Grid item xs={12}>
                <CircularProgress color="secondary"/>
            </Grid>
        );
    }

    return (
        <StyledHome id="home">
            <HomeNavigation/>
            <Grid container
                  direction="column"
                  alignItems="center"
                  className={classes.main}
                  component={"section"}
                  aria-labelledby={"services-heading"}
            >
                <Grid item
                      flexDirection="column"
                      justifyContent="center"
                      className={classes.logoWrapper}
                >
                    <img src="./logo.png" alt="Beaver Plumbers Logo" className={classes.logo}/>
                    <Typography variant="h1" gutterBottom className={classes.logoWrapperText}>
                        <strong>{window.location.origin.replace(/https?:\/\/|:\d+/g, '')}</strong><br/>
                        {translatedHome.heading}
                    </Typography>
                </Grid>
                <Grid container
                      spacing={3}
                      className={classes.services}
                      justifyContent="space-around"
                >
                    {serviceOrderTypes.map((serviceOrderType, index) => {
                        if (!serviceOrderType?.serviceOrderTypeDto) return null;

                        const {id, translationKey, name, image} = serviceOrderType.serviceOrderTypeDto;
                        const actionUrl = serviceOrderType.appointmentUrl;
                        const orderTypeBlockExtendedProps: OrderTypeBlockExtendedProps = {
                            id,
                            name,
                            translationKey,
                            image,
                            actionUrl,
                            typeIdentification: 'TYPE'
                        };

                        return (
                            <Grid key={index}
                                  item
                                  xs={12} md={6} lg={4}
                                  component={"article"}
                                  tabIndex={0}
                            >
                                <Paper
                                    className={classes.serviceItem}
                                    onClick={() => navigateToNextComponent(orderTypeBlockExtendedProps)}
                                    aria-label={serviceIcon[name].ariaLabel}
                                >
                                    {serviceIcon[name].icon || null}
                                    <Typography variant="subtitle1"
                                                component="h2"
                                                className={classes.serviceItemText}
                                    >
                                        {serviceOrderType.translation}
                                    </Typography>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </StyledHome>
    );
};

export default Home;
