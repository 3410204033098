const commonTranslate = {
    enUS: {
        company: 'Beaver Plumbers',
        email: 'ksi@beaver-plumbers.com',
        phone: '+48 537 777 308',
        currency: '€',
        navigation: {
            offer: {
                href: '/#construction-elements',
                label: 'Offer'
            },
            gallery: {
                href: '/#gallery',
                label: 'Gallery'
            },
            contact: {
                href: '/#contact',
                label: 'Contact'
            },
        },
        action: {
            contactButton: 'Contact us',
            logIn: 'Log In',
            myProfile: 'My Profile',
            logOut: 'Log Out',
            show: 'Show',
            check: 'Check',
            close: 'Close',
            home: 'Main Page',
        },
        gallery: {
            imagesPath: '/assets/gallery-content/',
        },
    },
    plPL: {
        company: 'Beaver Plumbers',
        email: 'ksi@beaver-plumbers.com',
        phone: '+48 537 777 308',
        currency: 'zł',
        navigation: {
            offer: {
                href: '/#construction-elements',
                label: 'Oferta'
            },
            gallery: {
                href: '/#gallery',
                label: 'Realizacje'
            },
            contact: {
                href: '/#contact',
                label: 'Kontakt'
            },
        },
        action: {
            contactButton: 'Umów instalację',
            logIn: 'Zaloguj Się',
            myProfile: 'Mój profil',
            logOut: 'Wyloguj Się',
            show: 'Pokaż',
            check: 'Sprawdź',
            close: 'Zamknij',
            home: 'Wróć na Główną',
        },
        gallery: {
            imagesPath: '/assets/gallery-content/',
        },
    }
}

export default commonTranslate;
