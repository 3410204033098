import React, {useCallback, useContext, useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {classes} from "./construction-elements-style"
import {Button, useMediaQuery} from "@mui/material";
import ConstructionElementsActions from "./construction-elements-actions/construction-elements-actions";
import ConstructionElementsContent from "./construction-elements-content/construction-elements-content";
import {DialogContext} from "../../context/dialog-context/dialog-context";
import {LocaleContext} from "../../context/locale-context/locale-context";
import translate from "../../language/translate";
import {defaultLocale} from "../../context/locale-context/default-locale";

const StyledConstructionElements = styled(Box)(({theme}) => ({

    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.primary.background,

    [`& .${classes.imageWrapper}`]: {
        display: 'inline-block',
        alignItems: 'center',
        minHeight: '90%',
    },
    [`& .${classes.imageContainer}`]: {
        position: 'absolute',
        zIndex: 100,
        width: '42%',
        height: '85%',
        backgroundPosition: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: "contain",
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            height: '20vh',
        },
    },
    [`& .${classes.imageWrapperTopBox}`]: {
        minHeight: '30vh',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: '8vw'
    },
    [`& .${classes.imageWrapperBottomBox}`]: {
        paddingLeft: '8vw'
    },
    [`& .${classes.contentWrapper}`]: {
        display: 'inline-block',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
        },
    },
    [`& .${classes.content}`]: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: '8vw',
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '8vw',
        },
    },
    [`& .${classes.buttonContainer}`]: {
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: 20,
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
        },
    },
    [`& .${classes.contactActionButton}`]: {
        color: theme.palette.primary.background,
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

function ConstructionElements() {

    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translatedOffer = translation.offer;
    const {setVisible} = useContext(DialogContext);
    const [actionButtons, setActionButtons] = useState([]);
    const [selectedConstructionElement, setSelectedConstructionElement] = useState(null);
    const [selectedConstructionElementImage, setSelectedConstructionElementImage] = useState(null);

    const isMiddleOrSmallerScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const selectConstructionElement = useCallback((button) => {
        setSelectedConstructionElement(button.action);
        setSelectedConstructionElementImage(button.image);
    }, []);

    useEffect(() => {
        let buttons = translatedOffer.actions.buttons;
        if (buttons) {
            setActionButtons(buttons);
            setSelectedConstructionElement(buttons.SERVICE_24_7.action);
            setSelectedConstructionElementImage(buttons.SERVICE_24_7.image);
        }
    }, []);

    return (
        <StyledConstructionElements id="construction-elements"
                                    component="section"
        >
            <Grid container
                  flexDirection={"row"}
                  style={{flexGrow: 1}}
            >
                {!isMiddleOrSmallerScreen &&
                    <Grid item
                          container
                          xs={5}
                          className={classes.imageWrapper}
                    >
                        <Box className={classes.imageContainer}
                             sx={{backgroundImage: `url("${selectedConstructionElementImage}")`}}/>
                        <Box className={classes.imageWrapperTopBox}/>
                        <Box className={classes.imageWrapperBottomBox}/>
                    </Grid>
                }
                <Grid item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={7}
                      xl={7}
                      className={classes.contentWrapper}
                >
                    <ConstructionElementsActions
                        actionButtons={actionButtons}
                        selectConstructionElement={selectConstructionElement}
                    />
                    {isSmallScreen && <Box className={classes.imageContainer}
                                           sx={{backgroundImage: `url("${selectedConstructionElementImage}")`}}/>}
                    <Box className={classes.content}>
                        <ConstructionElementsContent actionButtons={actionButtons}
                                                     selectedContent={selectedConstructionElement}
                        />
                        {selectedConstructionElement !== translatedOffer.actions.buttons.SERVICE_24_7.action &&
                            <Grid container
                                  className={classes.buttonContainer}
                            >
                                <Button className={classes.contactActionButton}
                                        variant="contained"
                                        size="medium"
                                        onClick={() => setVisible(true)}
                                >
                                    {translatedOffer.contactButton}
                                </Button>
                            </Grid>
                        }
                    </Box>
                </Grid>
            </Grid>
        </StyledConstructionElements>
    );
}

export default ConstructionElements;
