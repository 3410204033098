import React, {useContext, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useOrderApi} from "../../../order-managment/api/order-api";
import {classes} from "./construction-elements-service-offer-styles"
import {useNavigate} from "react-router-dom";
import {LocaleContext} from "../../../../context/locale-context/locale-context";
import translate from "../../../../language/translate";
import {defaultLocale} from "../../../../context/locale-context/default-locale";

const StyledConstructionElementsServiceOffer = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.primary.background,
    paddingBottom: '16px',
    [`& .${classes.typeNameWrapper}`]: {
        display: "flex",
        alignItems: "baseline"
    },
    [`& .${classes.divider}`]: {
        borderColor: theme.palette.primary.main,
    }
}));

const ConstructionElementsServiceOffer = () => {
    const {fetchAllServiceOrderTypes, fetchAllServiceOrderTypeSubtypes} = useOrderApi();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [groupedServices, setGroupedServices] = useState([]);
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translatedOffer = translation.offer;

    useEffect(() => {
        const loadServices = async () => {
            setLoading(true);
            try {
                const serviceTypes = await fetchAllServiceOrderTypes();
                const updatedTypes = serviceTypes.map(type => ({
                    ...type,
                    appointmentUrl: `/order/${encodeURIComponent(type.serviceOrderTypeDto.name)}/subtype/list`
                }));

                const subtypesPromises = updatedTypes.map(type =>
                    fetchAllServiceOrderTypeSubtypes(type.serviceOrderTypeDto.name).then(subtypes => ({
                        typeName: type.translation,
                        appointmentUrl: type.appointmentUrl,
                        subtypes: subtypes.map(subtype => ({
                            id: subtype.serviceOrderSubtypeDto.id,
                            name: subtype.translation,
                            image: subtype.serviceOrderSubtypeDto.image
                        }))
                    }))
                );

                const grouped = await Promise.all(subtypesPromises);
                setGroupedServices(grouped);
            } catch (error) {
                console.error("Error loading services:", error);
            } finally {
                setLoading(false);
            }
        };

        loadServices();
    }, []);

    if (loading) {
        return (
            <StyledConstructionElementsServiceOffer>
                <CircularProgress/>
                <Typography variant={"h2"}>
                    {translatedOffer.actions.noAction}
                </Typography>
            </StyledConstructionElementsServiceOffer>
        );
    }

    return (
        <StyledConstructionElementsServiceOffer>
            <Grid container flexDirection={"column"}>
                <Typography variant="h2" gutterBottom>
                    {translatedOffer.actions.content.serviceOffer.title}
                </Typography>
                <Typography variant="paragraph"
                            component="p"
                            gutterBottom
                            dangerouslySetInnerHTML={{__html: translatedOffer.actions.content.serviceOffer.description}}/>
                <Divider sx={{my: 2}} className={classes.divider}/>
                <Grid container item>
                    {groupedServices.map(group => (
                        <Grid container item xs={12} sm={4} key={group.typeName}>
                            <Grid item flexDirection={"row"} className={classes.typeNameWrapper}>
                                <Typography variant="h2" gutterBottom>
                                    {group.typeName}
                                </Typography>
                                <Button color="primary" onClick={() => navigate(group.appointmentUrl)}>
                                    {translatedOffer.actions.content.serviceOffer.orderAction}
                                </Button>
                            </Grid>
                            <List>
                                {group.subtypes.map(subtype => (
                                    <ListItem key={subtype.id}>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="paragraph" component="p">
                                                <strong>{subtype.name}</strong>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </StyledConstructionElementsServiceOffer>
    );
};

export default ConstructionElementsServiceOffer;
