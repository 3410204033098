import {styled} from '@mui/material/styles';
import React, {useState} from "react";

import {Box} from "@mui/material";
import ContactInput from "../contact-input/contact-input";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import type {AddressValid} from "../shared/dto/address-valid";
import ContactInputSelect from "../contact-input-select/contact-input-select";

const StyledContactAddress = styled(Box)(({theme}) => ({
    display: 'contents'
}));

const ContactAddress = ({inverseColors, contact, onContactChange}) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;

    const [address: AddressValid, onAddressChange] = useState({...contact.address});

    const formChanged = (event) => {
        const attributeId = event.target.id ? event.target.id : event.target.name;
        const addressAfterChange: AddressValid = {
            ...address,
            [attributeId]: {
                ...address[attributeId],
                value: event.target.value
            }
        };
        onAddressChange(addressAfterChange);
        onContactChange({...contact, address: addressAfterChange});
    };

    return (
        <StyledContactAddress>
            <ContactInputSelect id={'city'}
                                inverseColors={inverseColors}
                                value={address.city?.value}
                                onChange={formChanged}
                                label={translatedContact.form.city.label}
                                inputPlaceholder={translatedContact.form.city.placeholder}
                                selectPlaceholder={translatedContact.form.citySelection.placeholder}
                                validity={address.city?.validity}
                                options={translatedContact.form.citySelection.options}
            />
            <ContactInput id={'street'}
                          inverseColors={inverseColors}
                          value={address.street?.value}
                          onChange={formChanged}
                          label={translatedContact.form.street.label}
                          inputPlaceholder={translatedContact.form.street.placeholder}
                          validity={address.street?.validity}
            />
            <ContactInput id={'house'}
                          inverseColors={inverseColors}
                          value={address.house?.value}
                          onChange={formChanged}
                          label={translatedContact.form.house.label}
                          inputPlaceholder={translatedContact.form.house.placeholder}
                          validity={address.house?.validity}
            />
            <ContactInput id={'houseNumber'}
                          inverseColors={inverseColors}
                          value={address.houseNumber?.value}
                          onChange={formChanged}
                          label={translatedContact.form.houseNumber.label}
                          inputPlaceholder={translatedContact.form.houseNumber.placeholder}
                          validity={address.houseNumber?.validity}
            />
            <ContactInput id={'postalCode'}
                          inverseColors={inverseColors}
                          value={address.postalCode?.value}
                          onChange={formChanged}
                          label={translatedContact.form.postalCode.label}
                          inputPlaceholder={translatedContact.form.postalCode.placeholder}
                          validity={address.postalCode?.validity}
            />
        </StyledContactAddress>
    );
}

export default ContactAddress;
