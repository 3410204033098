import {classes} from "./home-navigation-navbar-styles";
import {Link, List, ListItem, Typography} from "@mui/material";
import HomeNavigationProfileButton from "../home-navigation-profile-button/home-navigation-profile-button";
import React from "react";
import {styled} from "@mui/material/styles";

const StyledHomeNavigationNavbar = styled('nav')(({theme}) => ({
    display: 'flex',
    gap: '40px',
    height: '8vh',
    alignItems: 'center',
    padding: '0 8vw',

    [`& .${classes.navList}`]: {
        listStyle: 'none',
        display: 'contents',
    },
    [`& .${classes.navLink}`]: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 'bold',
        transition: 'color 0.3s, background-color 0.3s',
        padding: theme.spacing(1),
        borderRadius: '4px',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.background,
        },
    },
}));

const HomeNavigationNavbar = ({navLinks}) => (
    <StyledHomeNavigationNavbar>
        <List className={classes.navList}>
            {navLinks.map((link, index) => (
                <ListItem key={index}>
                    <Link href={link.href}>
                        <Typography variant="subtitle1" component="span" className={classes.navLink}>
                            {link.label}
                        </Typography>
                    </Link>
                </ListItem>
            ))}
            <li>
                <HomeNavigationProfileButton/>
            </li>
        </List>
    </StyledHomeNavigationNavbar>
);

export default HomeNavigationNavbar;
