import {useAuth} from "react-oidc-context";
import {useApi} from "../../../context/api-context/api-context";
import type {TechnicianData} from "../shared/dto/technician-data";

export const useTechnicianApi = () => {
    const auth = useAuth();
    const api = useApi();

    const getAuthHeaders = () => {
        if (!auth.user?.access_token) {
            throw new Error('Access token is not available.');
        }
        return {
            Authorization: `Bearer ${auth.user?.access_token}`,
        };
    };

    const findOrdersForTechnician = async (email, availabilityDate) => {
        try {
            const response = await api.post('/technician/order/list',
                {
                    email: email,
                    availabilityDate: availabilityDate
                },
                {
                    headers: getAuthHeaders(),
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching orders for technician:', error);
            throw error;
        }
    };

    const fetchTechnicianData = async (): Promise<TechnicianData> => {
        try {
            const response = await api.get(`/technician/profile`, {
                headers: getAuthHeaders(),
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching technician data:', error);
            throw error;
        }
    };

    const updateTechnicianAvailability = async (technicianId: string, availabilityId: string, selectedDate: Date, hour: number, isAvailable: boolean): void => {
        try {
            const response = await api.post('/technician/availability',
                {
                    id: availabilityId,
                    technicianId: technicianId,
                    selectedDate: selectedDate,
                    hour: hour,
                    isAvailable: isAvailable
                },
                {
                    headers: getAuthHeaders(),
                });
            return response.data;
        } catch (error) {
            console.error('Error updating availability for technician:', error);
            throw error;
        }
    };

    return {findOrdersForTechnician, fetchTechnicianData, updateTechnicianAvailability};
};
