import React, {useState} from "react";
import {styled} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import GalleryModal from "./gallery-modal/gallery-modal";
import {classes} from "./gallery-style"
import {classes as classesApp} from "../app-styles";
import {LocaleContext} from "../../context/locale-context/locale-context";
import translate from "../../language/translate";
import {Typography} from "@mui/material";
import GalleryContentDescription from "./gallery-content-description/gallery-content-description";
import {defaultLocale} from "../../context/locale-context/default-locale";

const StyledGallery = styled(Box)(({theme}) => ({

    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [`& .${classes.headerWrapper}`]: {
        minHeight: '15vh',
        alignItems: 'center'
    },

    [`& .${classes.headerTitle}`]: {
        minWidth: '100%',
        color: theme.palette.primary.main,
        textAlign: 'left',
        zIndex: 100,
        paddingTop: 10,
        paddingBottom: 10,
    },

    [`& .${classes.headerContent}`]: {
        zIndex: 100,
        color: theme.palette.primary.background,
        whiteSpace: 'pre-line',
    },

    [`& .${classes.headerBackground}`]: {
        position: 'absolute',
        minHeight: 'calc(15vh + 30px)',
        minWidth: '100%',
        backgroundColor: theme.palette.secondary.main,
        zIndex: 0,
        left: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: '75%',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(30vh + 30px)',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            minWidth: '95%',
        },
        [theme.breakpoints.only('md')]: {
            minWidth: '95%',
        },
    },

    [`& .${classes.galleryContainerWrapper}`]: {
        flexGrow: 1,
        marginTop: 20,
    },

    [`& .${classes.galleryContainer}`]: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
    },

    [`& .${classes.cardContent}`]: {
        display: "flex",
        height: '30vh',
        position: 'relative',
        transition: 'all 1s ease',
        borderRadius: 5,
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },

    [`& .${classes.cardContentInfo}`]: {
        backgroundColor: theme.palette.primary.main,
        width: '70%',
        borderRadius: 10,
    },

    [`& .${classes.cardContentActionArea}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    [`& .${classes.cardContentImage}`]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        backgroundPosition: 'center',
    },

    [`& .${classes.text}`]: {
        margin: 0,
        color: theme.palette.primary.background,
    },

    [`& .${classes.galleryContentDescriptionWrapper}`]: {
        paddingTop: 20
    },

}));

function Gallery() {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translationGallery = translation.gallery;

    const [isOpen, setIsOpen] = useState(false);
    const [whoId, setWhoId] = useState(99);

    const open = () => {
        setIsOpen(true)
    };

    const close = () => {
        setIsOpen(false)
    };

    function openModal(index) {
        return () => {
            open();
            setWhoId(index)
        };
    }

    let galleryContentDescriptionRatio = {
        small: 12,
        middle: 12,
        large: 12,
    };

    return (
        <StyledGallery id="gallery"
                       component="section"
                       className={classesApp.bottom}
        >
            <Grid container className={classes.headerWrapper}>
                <Box className={classes.headerBackground}/>
                <Typography variant={"h2"} className={classes.headerTitle}>
                    {translationGallery.header}
                </Typography>
                <Typography
                    variant="paragraph"
                    component="p"
                    gutterBottom
                    className={classes.headerContent}
                    dangerouslySetInnerHTML={{__html: translationGallery.headerDescription}}
                />
            </Grid>
            <Grid container
                  direction={"row"}
                  className={classes.galleryContainerWrapper}
            >
                <Grid item
                      container
                      direction={"row"}
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={9}
                >
                    {
                        translationGallery.galleryContent.map((gallery, index) => {
                            return (
                                <Grid item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      key={index}
                                      className={classes.galleryContainer}
                                >
                                    <Card className={classes.cardContent}>
                                        <CardMedia
                                            component="img"
                                            className={classes.cardContentImage}
                                            src={gallery.images[0]}
                                            alt={gallery.alt}
                                            title={gallery.title}
                                        />
                                        <CardActionArea
                                            onClick={openModal(index)}
                                            className={classes.cardContentActionArea}
                                        >
                                            <CardContent className={classes.cardContentInfo}>
                                                <Typography variant={'h3'}
                                                            className={classes.text}
                                                >
                                                    {gallery.title}
                                                </Typography>
                                                <Typography
                                                    variant="paragraph"
                                                    component="p"
                                                    gutterBottom
                                                    className={classes.text}
                                                    dangerouslySetInnerHTML={{__html: gallery.content}}
                                                />
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    <GalleryModal number={index}
                                                  ident={whoId}
                                                  open={isOpen}
                                                  onClose={close}
                                                  alt={gallery.alt}
                                    >
                                        {gallery.images}
                                    </GalleryModal>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid item
                      xs={0}
                      sm={0}
                      md={3}
                      lg={3}
                      xl={3}
                      className={classes.galleryContentDescriptionWrapper}
                >
                    <GalleryContentDescription ratio={galleryContentDescriptionRatio}/>
                </Grid>
            </Grid>
        </StyledGallery>
    );
}

export default Gallery;
