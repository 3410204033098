import React from "react";
import {AuthConfigContext} from "./auth-config-context";
import {AuthProvider} from "react-oidc-context";

export const AuthConfigContextProvider = ({children}) => {
    const cognitoAuthConfig = {
        authority: `https://cognito-idp.${process.env.REACT_APP_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
        client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
        redirect_uri: `${window.location.origin}/oauth2/callback`,
        post_logout_redirect_uri: `${window.location.origin}`,
        response_type: "code",
        scope: "email openid phone",
    };

    return (
        <AuthConfigContext.Provider value={cognitoAuthConfig}>
            <AuthProvider {...cognitoAuthConfig}>
                {children}
            </AuthProvider>
        </AuthConfigContext.Provider>
    );
};
