import {styled} from '@mui/material/styles';
import React from "react";
import {classes} from "./app-styles";
import Home from "./home/home";
import ConstructionElements from "./construction-elements/construction-elements";
import Gallery from "./gallery/gallery";
import ContactAndFooterMenu from "./contact-and-footer-menu/contact-and-footer-menu";

const StyledAppContent = styled('section')(({theme}) => ({
    minHeight: "100vh",

    [`& .${classes.gridTextLeftAlign}`]: {
        [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingBottom: '16px',
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "18px",
        },
        "& h1, h2, h3, h4, h5": {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.background,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                fontSize: "32px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "36px",
            },
        },
        "& p": {
            textAlign: 'left',
            margin: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "18px",
            },
        }
    },

    [`& .${classes.gridText}`]: {
        [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingBottom: '16px',
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "18px",
        },
        "& h1, h2, h3, h4, h5": {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.background,
            [theme.breakpoints.down('md')]: {
                fontSize: "32px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "36px",
            },
        },
        "& p": {
            color: theme.palette.primary.background,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "18px",
            },
        },
        "& span": {
            color: theme.palette.primary.main,
        }
    },

    [`& .${classes.top}`]: {
        backgroundSize: 'cover',
        backgroundColor: 'light' === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },

    [`& .${classes.topMain}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
    },

    [`& .${classes.topSection}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : '#E4E4E4',
        overflow: 'hidden',
        paddingLeft: '8px',
        paddingRight: '8px',
    },

    [`& .${classes.topFooter}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
        paddingTop: '16px',
    },

    [`& .${classes.bottom}`]: {
        color: theme.palette.secondary.main,
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#EEEEEE' : theme.palette.secondary.background,
        overflow: 'hidden',
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },

}));

const AppContent = () => {
    return (
        <StyledAppContent>
            <Home/>
            <ConstructionElements/>
            <Gallery/>
            <ContactAndFooterMenu/>
        </StyledAppContent>
    );
}

export default AppContent;
