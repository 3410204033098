import React, {useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {Paper, Tab, Tabs, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {classes} from "./technician-styles";
import TechnicianProfile from "./technician-profile/technician-profile";
import TechnicianServiceOrder from "./technician-service-order/technician-service-order";
import {useTechnicianApi} from "./api/technician-api";
import type {TechnicianData} from "./shared/dto/technician-data";
import ActionButtons from "../common/action-buttons/action-buttons";

const StyledTechnician = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.primary.background,
    width: '100%',
    textAlign: 'center',
    margin: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    [`& .${classes.main}`]: {
        width: '100%',
        flexGrow: 1,
        boxSizing: 'border-box',
        padding: '0 8vw',
    },
    [`& .${classes.technicianCard}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    [`& .${classes.tabsContainer}`]: {
        marginBottom: theme.spacing(2),
    },
}));

const Technician = (props) => {

    const auth = useAuth();
    const {fetchTechnicianData} = useTechnicianApi();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(0);
    const [technician: TechnicianData, setTechnician] = useState(null);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        const fetchTechnician = async () => {
            try {
                const technicianData: TechnicianData = await fetchTechnicianData();
                setTechnician(technicianData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        if (auth.isAuthenticated) {
            fetchTechnician();
        }
    }, [auth.isAuthenticated, auth.user]);

    if (!auth.isAuthenticated) {
        return (
            <StyledTechnician>
                <ActionButtons
                    showLogin={true}
                    showHome={true}
                />
            </StyledTechnician>
        );
    }

    if (loading) {
        return (
            <StyledTechnician>
                <Typography>Loading...</Typography>
            </StyledTechnician>
        );
    }

    if (auth.error || error) {
        return (
            <StyledTechnician>
                <Box>Encountering error... {auth?.error?.message ? auth?.error?.message : error}</Box>
            </StyledTechnician>
        );
    }

    if (auth.isAuthenticated) {
        return (
            <StyledTechnician>
                <Box className={classes.main}>
                    <Box className={classes.tabsContainer}>
                        <Tabs
                            value={currentTab}
                            onChange={handleChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Profile"/>
                            <Tab label="Service Orders"/>
                            <Tab label="Billing"/>
                        </Tabs>
                    </Box>

                    {currentTab === 0 && (
                        <TechnicianProfile technician={technician}/>
                    )}

                    {currentTab === 1 && (
                        <TechnicianServiceOrder technician={technician}/>
                    )}

                    {currentTab === 2 && (
                        <Paper className={classes.technicianCard}>
                            <Typography variant={"h3"}>Billing</Typography>
                            <Typography>Content for Billing will go here...</Typography>
                        </Paper>
                    )}
                </Box>
                <ActionButtons
                    showLogout={true}
                    showHome={true}
                />
            </StyledTechnician>
        );
    }

    return (
        <StyledTechnician>
            <ActionButtons
                showLogin={true}
                showHome={true}
            />
        </StyledTechnician>
    );
};

export default Technician;
