import * as React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TimelineContent from "@mui/lab/TimelineContent";
import {Button} from "@mui/material";
import {classes} from "./appointment-selection-line-styles";
import {ServiceOrderDetails} from "../../shared/dto/service-order-details";
import {useNavigate} from "react-router-dom";
import {ServiceOrderContext} from "../../../../context/service-order-context/service-order-context";

const TimeLine = ({date, hour, isSlotAvailable}) => {

    const navigate = useNavigate();
    const {setServiceOrderDetails} = React.useContext(ServiceOrderContext);

    const navigateToAppointmentOrderDetails = (hour) => {
        const serviceOrderDetails: ServiceOrderDetails = {hour, day: date, price: '299,99'};
        setServiceOrderDetails(serviceOrderDetails);
        navigate('/order/appointment/order-details');
    };

    return (
        <TimelineItem key={`${date}-${hour}`}>
            <TimelineOppositeContent
                sx={{m: "auto 0"}}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                <Typography variant={"h3"} component="span">
                    {hour}:00
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector/>
                <TimelineDot>
                    {isSlotAvailable ? (
                        <CheckCircleIcon style={{color: "green"}}/>
                    ) : (
                        <CancelIcon style={{color: "red"}}/>
                    )}
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent sx={{alignContent: "center"}}>
                {isSlotAvailable ? (
                    <Button className={classes.specialLink}
                            size="medium"
                            fullWidth
                            onClick={() => navigateToAppointmentOrderDetails(hour)}
                    >
                        <Typography className={classes.actionsTitle}
                                    variant={"h3"}
                                    component="span"
                        >
                            Umów
                        </Typography>
                    </Button>
                ) : (
                    <Button variant="outlined"
                            size="medium"
                            fullWidth
                    >
                        <Typography className={classes.actionsTitleOutlined}
                                    variant={"h3"}
                                    component="span"
                        >
                            Umów
                        </Typography>
                    </Button>
                )}
            </TimelineContent>
        </TimelineItem>
    );
}

export default TimeLine;
