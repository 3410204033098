import React, {useContext, useState} from "react";
import {classes} from "./token-dialog-styles";
import {Key} from "@mui/icons-material";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {LocaleContext} from "../../../context/locale-context/locale-context";

const StyledTokenDialog = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    position: 'relative',

    [`& .${classes.icon}`]: {
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
    },

    [`& .${classes.content}`]: {
        overflowWrap: "break-word", // Standard property
        wordBreak: "break-word",    // Legacy support
        wordWrap: "break-word",     // Fallback for older environments
    },
}));

const TokenDialog = ({label, value}) => {

    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedTechnician = translation.technician;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <StyledTokenDialog>
            <Key className={classes.icon}/>
            <Typography>{label}</Typography>
            <Button size="small" onClick={handleClickOpen}>
                {translatedTechnician.token.action.show}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <Typography className={classes.content} style={{wordWrap: "break-word"}}>{value}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {translatedTechnician.token.action.close}
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledTokenDialog>
    );
};

export default TokenDialog;
