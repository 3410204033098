import React from 'react';
import {useAuth} from "react-oidc-context";
import {Paper, Typography, useMediaQuery} from "@mui/material";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {classes} from "./technician-profile-styles";
import {Email, LocationOn, Map, Phone} from "@mui/icons-material";
import TokenDialog from "../../common/token-dialog/token-dialog";
import type {TechnicianData} from "../shared/dto/technician-data";
import TechnicianProfilePriceStrategyTable
    from "./technician-profile-price-strategy-table/technician-profile-price-strategy-table";
import TechnicianProfilePriceStrategyAccordion
    from "./technician-profile-price-strategy-accordion/technician-profile-price-strategy-accordion";

const StyledTechnicianProfile = styled(Box)(({theme}) => ({
    [`& .${classes.technicianCard}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    [`& .${classes.icon}`]: {
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
    },
    [`& .${classes.infoRow}`]: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        position: 'relative',
    },
}));

const TechnicianProfile = ({technician}: { technician: TechnicianData }) => {
    const auth = useAuth();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    if (auth.isAuthenticated) {
        return (
            <StyledTechnicianProfile>
                <Paper className={classes.technicianCard}>
                    <Typography variant={"h3"}>
                        {technician.firstName} {technician.lastName}
                    </Typography>
                    <Box className={classes.infoRow}>
                        <Phone className={classes.icon}/>
                        <Typography>{technician.contactNumber}</Typography>
                    </Box>
                    <Box className={classes.infoRow}>
                        <Email className={classes.icon}/>
                        <Typography>{technician.email}</Typography>
                    </Box>
                    <Box className={classes.infoRow}>
                        <LocationOn className={classes.icon}/>
                        <Typography>{`${technician.city}, ${technician.postalCode}, ${technician.country}`}</Typography>
                    </Box>
                    <Box className={classes.infoRow}>
                        <Map className={classes.icon}/>
                        <Typography>Service Range: {technician.serviceRange} km</Typography>
                    </Box>
                    <TokenDialog label="ID Token" value={auth.user?.id_token}/>
                    <TokenDialog label="Access Token" value={auth.user?.access_token}/>
                    <TokenDialog label="Refresh Token" value={auth.user?.refresh_token}/>
                </Paper>
                {
                    isSmallScreen ? <TechnicianProfilePriceStrategyAccordion
                            technicianPriceStrategies={technician.technicianPriceStrategies}/> :
                        <TechnicianProfilePriceStrategyTable
                            technicianPriceStrategies={technician.technicianPriceStrategies}/>
                }
            </StyledTechnicianProfile>
        );
    }

    return (
        <Typography variant="body1" color="textSecondary">
            User not authenticated. Please log in to view the technician profile.
        </Typography>
    );
};

export default TechnicianProfile;
