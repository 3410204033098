import React, {useContext, useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import {useTechnicianApi} from "../api/technician-api";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {AppContext} from "../../../context/app-context/app-context";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {classes} from "./technician-service-order-styles";
import PauseIcon from "@mui/icons-material/PauseCircle";
import CheckIcon from "@mui/icons-material/Check";

const StyledTechnicianServiceOrder = styled(Box)(({theme}) => ({
    [`& .${classes.orderCard}`]: {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        borderRadius: '8px',
        height: '150px',
        display: 'flex',
        padding: theme.spacing(0, 3),
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        border: `2px solid ${theme.palette.primary.background}`,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    [`& .${classes.orderCardFilled}`]: {
        backgroundColor: theme.palette.primary.background,
    },
    [`& .${classes.availableOrderCard}`]: {
        alignItems: 'center',
        border: `2px solid ${theme.palette.primary.main}`,
    },
    [`& .${classes.emptyOrderCard}`]: {
        alignItems: 'center',
        border: `2px solid ${theme.palette.secondary.main}`,
    },
    [`& .${classes.emptyOrderCardHour}`]: {
        color: theme.palette.secondary.main,
    },
}));

const hours = Array.from({length: 18}, (_, i) => i + 6);

const TechnicianServiceOrder = (props) => {

    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedTechnician = translation.technician;
    const auth = useAuth();
    const {getAvailabilityDays} = useContext(AppContext);
    const {findOrdersForTechnician, updateTechnicianAvailability} = useTechnicianApi();
    const [error, setError] = useState(null);
    const [dates] = useState(getAvailabilityDays());
    const [selectedDate, setSelectedDate] = useState("");
    const [serviceOrders, setServiceOrders] = useState({});
    const [availabilityId, setAvailabilityId] = useState();
    const [availableSlots, setAvailableSlots] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchOrdersAndAvailability(date) {
        let userEmail = auth.isAuthenticated ? auth.user?.profile.email : props.technician.email;
        const {serviceOrderByHourDto, availabilityWithTimeSlotDto} = await findOrdersForTechnician(userEmail, date);
        setServiceOrders(serviceOrderByHourDto);
        setAvailabilityId(availabilityWithTimeSlotDto.id);
        setAvailableSlots(availabilityWithTimeSlotDto.timeSlots.map(slot => parseInt(slot.split(':')[0])));
    }

    const handleDateChange = async (event) => {
        const selected = event.target.value;
        setSelectedDate(selected);

        try {
            await fetchOrdersAndAvailability(selected);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSlotAction = async (hour, isAvailable) => {
        try {
            await updateTechnicianAvailability(props.technician.id, availabilityId, selectedDate, hour, isAvailable);
            await fetchOrdersAndAvailability(selectedDate);
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                if (dates.length > 0) {
                    let date = dates[0];
                    await fetchOrdersAndAvailability(date);
                    setSelectedDate(date);
                }
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    if (!auth.isAuthenticated) {
        return (
            <StyledTechnicianServiceOrder>
                <Button onClick={() => auth.signinRedirect()}>Sign in</Button>
            </StyledTechnicianServiceOrder>
        );
    }

    if (auth.isLoading || loading) {
        return (
            <StyledTechnicianServiceOrder>
                <CircularProgress/>
            </StyledTechnicianServiceOrder>
        );
    }

    if (auth.error || error) {
        return (
            <StyledTechnicianServiceOrder>
                <Box>Encountering error... {auth?.error?.message ? auth?.error?.message : error}</Box>
            </StyledTechnicianServiceOrder>
        );
    }

    return (
        <StyledTechnicianServiceOrder>
            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="appointment-selection-date">Date for service</InputLabel>
                <Select
                    labelId="appointment-selection-date"
                    id="appointment-selection-date-selector"
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Date for service"
                >
                    {dates.map((date) => (
                        <MenuItem value={date} key={date}>{date}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container spacing={2} justifyContent="center">
                {hours.map((hour) => {
                    const order = serviceOrders[hour];
                    const isAvailable = availableSlots.includes(hour);
                    return (
                        <Grid key={hour} item xs={12} sm={6} md={4}>
                            {order ? (
                                <Paper className={`${classes.orderCard} ${classes.orderCardFilled}`}>
                                    <Typography variant={"h3"}>
                                        {order.serviceOrderDetailsDto.hour}:00
                                        - {order.serviceOrderTypeDto.name} ({order.serviceOrderSubtypeDto.name})
                                    </Typography>
                                    <Typography>{order.serviceOrderDetailsDto.price} PLN</Typography>
                                    <Typography>{order.serviceOrderDetailsDto.day}</Typography>
                                    <Typography>{`${order.addressDto.street}, ${order.addressDto.house}/${order.addressDto.houseNumber}, ${order.addressDto.city}, ${order.addressDto.postalCode}`}</Typography>
                                </Paper>
                            ) : (
                                <Paper
                                    className={`${classes.orderCard} ${isAvailable ? classes.availableOrderCard : classes.emptyOrderCard}`}>
                                    <Typography
                                        variant={"h3"}
                                        className={`${classes.emptyOrderCardHour}`}
                                    >
                                        {hour}:00
                                    </Typography>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color={isAvailable ? "primary" : "success"}
                                                size="small"
                                                startIcon={isAvailable ? <PauseIcon/> : <CheckIcon/>}
                                                onClick={() => handleSlotAction(hour, isAvailable)}
                                            >
                                                {isAvailable ? "Break" : "Active"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </StyledTechnicianServiceOrder>
    );
};

export default TechnicianServiceOrder;
