import React from "react";
import {Box, Typography} from "@mui/material";
import {classes} from "./footer-menu-right-styles";
import FooterMenuSocialProviderLink from "./footer-menu-social-provider-link/footer-menu-social-provider-link";
import {styled} from "@mui/material/styles";

const StyledFooterMenuRight = styled(Box)(({theme}) => ({
    [`& .${classes.middleMenuCategoryTitle}`]: {
        color: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
        marginBottom: 20,
        marginTop: 20,
    },
}));

const FooterMenuRight = (props) => {
    return (
        <StyledFooterMenuRight>
            <Typography variant={"h3"} className={classes.middleMenuCategoryTitle}>
                {props.menuHeader}
            </Typography>
            <FooterMenuSocialProviderLink/>
        </StyledFooterMenuRight>
    );
};

export default FooterMenuRight;
