import * as React from 'react';
import {Box, Link} from "@mui/material";
import {classes} from "./order-details-styles";
import styled from "@emotion/styled";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from "@mui/material/Typography";
import Contact from "../../contact/contact";
import OrderSummary from "../order-summary/order-summary";

const StyledOrderDetails = styled(Box)(({theme}) => ({
    display: 'flex',
    backgroundColor: '#F1F5F6',
    justifyContent: "center",
    minHeight: "100vh",

    [`& .${classes.content}`]: {
        width: "100%",
        paddingRight: "8vw",
        paddingLeft: "8vw",
    },
    [`& .${classes.offerElement}`]: {
        height: "90%",
        borderColor: "blue",
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        textAlign: 'center',
        lineHeight: '20px',

        backgroundImage: 'url("/assets/plumbing.jpg")',
        backgroundPosition: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
    },
}));

export default function OrderDetails() {

    return (
        <StyledOrderDetails>
            <Box className={`${classes.content}`}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/">
                        <Typography variant={"h3"}>Główna</Typography>
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/order"
                    >
                        <Typography variant={"h3"}>Zamówienie</Typography>
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/order/appointment"
                    >
                        <Typography variant={"h3"}>Wybór terminu</Typography>
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/order/appointment/order-details"
                    >
                        <Typography variant={"h3"}>Szczegóły zamówienia</Typography>
                    </Link>
                </Breadcrumbs>
                <Box flexGrow={1}
                     display={"flex"}
                     alignItems="center"
                     justifyContent="center">
                    <Contact extended>
                        <OrderSummary/>
                    </Contact>
                </Box>
            </Box>
        </StyledOrderDetails>
    );
}
