import {styled} from '@mui/material/styles';
import React from "react";

import {Avatar, Box, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from '@mui/material/CardHeader';
import ConstructionIcon from '@mui/icons-material/Construction';
import CardMedia from "@mui/material/CardMedia";
import {ServiceOrderContext} from "../../../context/service-order-context/service-order-context";

const StyledOrderSummary = styled(Box)(({theme}) => ({
    display: 'contents',
    justifyContent: 'center',
}));

const OrderSummary = () => {
    const {serviceOrderData} = React.useContext(ServiceOrderContext);

    return (
        <StyledOrderSummary>
            <Card variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <ConstructionIcon/>
                        </Avatar>
                    }
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon/>
                    //     </IconButton>
                    // }
                    title={
                        <Typography variant={"h3"}>
                            {serviceOrderData.serviceOrderSubtype.name}
                        </Typography>
                    }
                    subheader={
                        <Typography>
                            {serviceOrderData.serviceOrderDetails.day.toLocaleDateString('pl-PL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })} - {serviceOrderData.serviceOrderDetails.hour}:00
                        </Typography>
                    }
                />
                <CardMedia
                    component="img"
                    height="194"
                    image={serviceOrderData.serviceOrderSubtype.image}
                    alt="Subtype"
                />
                <CardContent>
                    <Typography variant="body1">
                        Podsumowanie oraz koszt zamawianej usługi znajdziesz poniżej.
                        <br/>
                    </Typography>
                    <Typography variant={"h3"} sx={{textAlign: "center"}}>
                        {serviceOrderData.serviceOrderDetails.price}
                    </Typography>
                </CardContent>
            </Card>
        </StyledOrderSummary>
    );
}

export default OrderSummary;
