import './app.css';
import {createTheme, responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import React, {useState} from "react";
import AppContent from "./app-content";
import * as locales from '@mui/material/locale';
import {LocaleContext} from "../context/locale-context/locale-context";
import {DialogContext} from "../context/dialog-context/dialog-context";
import ContactDialog from "./contact/contact-dialog/contact-dialog";
import {AppContextProvider} from "../context/app-context/app-context-provider";
import Info from "./common/info/info";
import {colorPalette} from "../util/color-palette";
import {defaultLocale} from "../context/locale-context/default-locale";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Order from "./order-managment/order/order";
import Appointment from "./order-managment/appointment/appointment";
import OrderDetails from "./order-managment/order-details/order-details";
import {ServiceOrderContextProvider} from "../context/service-order-context/service-order-context-provider";
import Technician from "./technician/technician";
import Callback from "./common/auth/callback";
import {AuthConfigContextProvider} from "../context/auth-config-context/auth-config-context-provider";
import {ApiProvider} from "../context/api-context/api-context-provider";

const DEFAULT_PALETTE_TYPE = 'light';

let theme = createTheme({
    palette: {
        mode: DEFAULT_PALETTE_TYPE,
        primary: {
            main: colorPalette.main,
            mainShadowed: colorPalette.mainShadowed,
            details: colorPalette.details,
            background: colorPalette.background,
            contrastText: 'white',
        },
        secondary: {
            main: colorPalette.base,
            mainShadowed: colorPalette.mainShadowed,
            details: colorPalette.details,
            background: colorPalette.background,
            contrastText: 'white',
        },
        additional: {
            mainShadowed: colorPalette.mainShadowed,
            background: colorPalette.background,
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Rambla',
            'Nunito',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        h3: {
            color: colorPalette.base,
            fontSize: "0.75rem",
            textAlign: 'left',
            fontWeight: "bold",
            letterSpacing: "1px",
            textTransform: "uppercase",
        },
        subtitle1: {
            color: colorPalette.base,
            fontSize: "0.8rem",
            textAlign: 'left',
            overflowY: 'auto',
        },
        button: {
            color: colorPalette.background,
            fontSize: "0.8rem",
        }
    }
});

theme = responsiveFontSizes(theme);


theme.typography.h1 = {
    color: colorPalette.base,
    fontSize: "1.2rem",
    textAlign: 'left',
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    [theme.breakpoints.up('xl')]: {
        fontSize: '1.15rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.15rem',
    },
};

theme.typography.h2 = {
    color: colorPalette.base,
    fontSize: "1.05rem",
    textAlign: 'left',
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    [theme.breakpoints.up('xl')]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
    },
};

theme.typography.h3 = {
    color: colorPalette.base,
    fontSize: "0.85rem",
    textAlign: 'left',
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    [theme.breakpoints.up('xl')]: {
        fontSize: '0.9rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
    },
};

theme.typography.paragraph = {
    color: colorPalette.base,
    fontSize: "0.80rem",
    textAlign: 'left',
    overflowY: 'auto',
    lineHeight: '1.3rem',
    [theme.breakpoints.up('xl')]: {
        fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
    },
};

theme.typography.body1 = {
    color: colorPalette.base,
    fontSize: "0.8rem",
    textAlign: 'left',
    overflowY: 'auto',
    [theme.breakpoints.up('xl')]: {
        fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
    },
};

theme.typography.subtitle1 = {
    color: colorPalette.base,
    fontSize: "0.85rem",
    textAlign: 'left',
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    [theme.breakpoints.up('xl')]: {
        fontSize: '0.9rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
    },
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppContent/>,
    },
    {
        path: "/order/:type/subtype/list",
        element: <Order/>,
    },
    {
        path: "/order/:type/subtype/:subtype/appointment",
        element: <Appointment/>,
    },
    {
        path: "/order/appointment/order-details",
        element: <OrderDetails/>,
    },
    {
        path: "/oauth2/callback",
        element: <Callback/>,
    },
    {
        path: "/technician",
        element: <Technician/>,
    }
]);

function App() {
    const [localeContext, setLocaleContext] = useState(defaultLocale);
    const [visible, setVisible] = useState(false);

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[localeContext.value]),
        [localeContext],
    );

    return (
        <AuthConfigContextProvider>
            <AppContextProvider>
                <ApiProvider>
                    <ServiceOrderContextProvider>
                        <LocaleContext.Provider value={{localeContext, setLocaleContext}}>
                            <DialogContext.Provider value={{visible, setVisible}}>
                                <ThemeProvider theme={themeWithLocale}>
                                    <Info/>
                                    <ContactDialog/>
                                    <RouterProvider router={router}>
                                        <AppContent/>
                                    </RouterProvider>
                                </ThemeProvider>
                            </DialogContext.Provider>
                        </LocaleContext.Provider>
                    </ServiceOrderContextProvider>
                </ApiProvider>
            </AppContextProvider>
        </AuthConfigContextProvider>
    );
}

export default App;
