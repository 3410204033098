import {styled} from '@mui/material/styles';
import React, {useState} from "react";

import {Box} from "@mui/material";
import ContactInput from "../contact-input/contact-input";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import ContactInputText from "../contact-input-text/contact-input-text";
import type {PersonalDataValid} from "../shared/dto/personal-data-valid";

const StyledContactPersonalData = styled(Box)(({theme}) => ({
    display: 'contents'
}));

const ContactPersonalData = ({inverseColors, contact, onContactChange}) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;

    const [personalData: PersonalDataValid, onPersonalDataChange] = useState({...contact.personalData});

    const formChanged = (event) => {
        const attributeId = event.target.id ? event.target.id : event.target.name;
        const personalDataAfterChange: PersonalDataValid = {
            ...personalData,
            [attributeId]: {
                ...personalData[attributeId],
                value: event.target.value
            }
        };
        onPersonalDataChange(personalDataAfterChange);
        onContactChange({...contact, personalData: personalDataAfterChange});
    };

    return (
        <StyledContactPersonalData>
            <ContactInput id={'name'}
                          inverseColors={inverseColors}
                          value={personalData.name?.value}
                          onChange={formChanged}
                          label={translatedContact.form.name.label}
                          inputPlaceholder={translatedContact.form.name.placeholder}
                          validity={personalData.name?.validity}
            />
            <ContactInput id={'email'}
                          inverseColors={inverseColors}
                          value={personalData.email?.value}
                          onChange={formChanged}
                          label={translatedContact.form.email.label}
                          inputPlaceholder={translatedContact.form.email.placeholder}
                          validity={personalData.email?.validity}
            />
            <ContactInput id={'phoneNumber'}
                          inverseColors={inverseColors}
                          value={personalData.phoneNumber?.value}
                          onChange={formChanged}
                          label={translatedContact.form.phone.label}
                          inputPlaceholder={translatedContact.form.phone.placeholder}
                          validity={personalData.phoneNumber?.validity}
            />
            <ContactInputText id={'message'}
                              inverseColors={inverseColors}
                              value={personalData.message?.value}
                              onChange={formChanged}
                              label={translatedContact.form.message.label}
                              inputPlaceholder={translatedContact.form.message.placeholder}
                              inputPlaceholderMessage={translatedContact.form.message.placeholderMessage}
                              validity={personalData.message?.validity}
            />
        </StyledContactPersonalData>
    );
}

export default ContactPersonalData;
