import Grid from "@mui/material/Grid";
import {styled} from '@mui/material/styles';
import React, {useContext, useState} from "react";
import {classes} from "./contact-input-privacy-policy-styles"
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const StyledContactInputPrivacyPolicy = styled(Grid)(({theme}) => ({

    [`& .${classes.consentTitle}`]: {
        color: theme.palette.primary.background,
    },
    [`& .${classes.checkboxContainerAlign}`]: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
            padding: '0px 12px 0px 12px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            textAlign: 'left',
            display: 'flex',
            flexDirection: "column-reverse",
            padding: '12px 0px 12px 12px',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column-reverse",
            padding: '12px 0px 12px 12px',
        },
    },
}));


const ContactInputPrivacyPolicy = (props) => {
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;
    const [gdprDialogOpen, setGdprDialogOpen] = useState(false);

    const openPrivacyPolicyDialog = (e) => {
        e.preventDefault();
        setGdprDialogOpen(true);
    };

    return (
        <StyledContactInputPrivacyPolicy container
                                         flexDirection={"row"}
        >
            <Grid item
                  xs={12} sm={12} md={3} lg={4} xl={4}
            />
            <Grid item
                  container
                  flexDirection={"row"}
                  alignItems={"center"}
                  xs={12} sm={12} md={9} lg={8} xl={8}
                  className={classes.checkboxContainerAlign}
            >
                <Checkbox
                    checked={props.privacyPolicyAccepted}
                    onChange={() => props.setPrivacyPolicyAccepted(!props.privacyPolicyAccepted)}
                />
                <Typography variant="paragraph"
                            component={"p"}
                            className={`${!props.extended && classes.consentTitle}`}
                >
                    <strong>{translatedContact.form.privacyPolicy.consent.title}</strong>
                    <Link href="#" onClick={openPrivacyPolicyDialog}>
                        <strong> {translatedContact.form.privacyPolicy.consent.titleLink}</strong>
                    </Link>
                </Typography>
            </Grid>
            <Dialog open={gdprDialogOpen} onClose={() => setGdprDialogOpen(false)}>
                <DialogTitle>
                    <Typography variant={"h2"}>
                        {translatedContact.form.privacyPolicy.policy.title}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="paragraph"
                                component={"p"}
                                dangerouslySetInnerHTML={{__html: translatedContact.form.privacyPolicy.policy.content}}
                    />
                </DialogContent>
            </Dialog>
        </StyledContactInputPrivacyPolicy>
    );
}

export default ContactInputPrivacyPolicy;
