const PREFIX = 'home';

export const classes = {
    header: `${PREFIX}-header`,
    main: `${PREFIX}-main`,
    logoWrapper: `${PREFIX}-logo-wrapper`,
    logo: `${PREFIX}-logo`,
    services: `${PREFIX}-services`,
    serviceItem: `${PREFIX}-service-item`,
    icon: `${PREFIX}-icon`,
    serviceItemHoverIcon: `${PREFIX}-service-item-hover-icon`,
    logoWrapperText: `${PREFIX}-logo-wrapper-text`,
    serviceItemText: `${PREFIX}-service-item-text`,
};
