const PREFIX = 'AppContent';

export const classes = {
    gridText: `${PREFIX}-gridText`,
    top: `${PREFIX}-top`,
    topMain: `${PREFIX}-topMain`,
    topSection: `${PREFIX}-topSection`,
    topFooter: `${PREFIX}-topFooter`,
    bottom: `${PREFIX}-bottom`,
    gridTextLeftAlign: `${PREFIX}-gridTextLeftAlign`,
};
