import React, {useEffect, useState} from 'react'
import {styled} from '@mui/material/styles';
import ReactDom from 'react-dom';
import GalleryModalImage from "../gallery-modal-image/gallery-modal-image";
import {classes} from "./gallery-modal-styles";

const StyledGalleryModal = styled('div')(({theme}) => ({

    [`&.${classes.overlay}`]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 1000,
    },

    [`& .${classes.closeArea}`]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },

    [`& .${classes.slider}`]: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '80%',
        height: '80%',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        zIndex: 10000,
    },

    [`& .${classes.slide}`]: {
        textAlign: "center",
        position: 'relative',
        minWidth: '100%',
        transition: '0.5s',
        overflow: 'hidden',
    },

    [`& .${classes.goLeft}`]: {
        backgroundColor: 'rgba(252, 252, 252, 0.7)',
        borderRadius: '1rem',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        width: '5rem',
        height: '5rem',
        [theme.breakpoints.down('sm')]: {
            width: '4rem',
            height: '4rem',
        },
        border: 'none',
        outline: 'none',
        zIndex: 1000,
        transition: 'all 1s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: '0.7',
        },
    },

    [`& .${classes.goRight}`]: {
        backgroundColor: 'rgba(252, 252, 252, 0.7)',
        borderRadius: '1rem',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        width: '5rem',
        height: '5rem',
        [theme.breakpoints.down('sm')]: {
            width: '4rem',
            height: '4rem',
        },
        border: 'none',
        outline: 'none',
        zIndex: 1000,
        transition: 'all 1s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: '0.7',
        }
    },

    [`& .${classes.close}`]: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '40px',
        height: '40px',
        margin: '2%',
        borderRadius: '50%',
        border: 'none',
        background: 'white',
        transition: 'all 1s ease',
        '&:hover': {
            transform: 'scale(1.15)',
        }
    },

    [`& .${classes.carouselButtonArrow}`]: {
        position: "absolute",
        width: "10px",
        height: "10px",
        top: "50%",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
    },

    [`& .${classes.carouselButtonArrowPrev}`]: {
        left: "54%",
        "transform": "translate(-50%, -50%) rotate(135deg)",
    },

    [`& .${classes.carouselButtonArrowNext}`]: {
        left: "47%",
        "transform": "translate(-50%, -50%) rotate(-45deg)",
    }
}));


function GalleryModal({number, ident, open, children, onClose, alt}) {

    const images = children

    const [x, setX] = useState(0);
    const [domReady, setDomReady] = useState(false)

    useEffect(() => {
        setDomReady(true)
    }, [])

    const goLeft = () => {
        x === 0 ? setX(-100 * (images.length - 1)) : setX(x + 100);
    };

    const goRight = () => {
        (x === -100 * (images.length - 1)) ? setX(0) : setX(x - 100);
    };

    if (!open || number !== ident) return null;


    return domReady ? ReactDom.createPortal(
        <StyledGalleryModal className={classes.overlay}>
            <div className={classes.closeArea} onClick={onClose}></div>
            <button className={classes.close} onClick={onClose}>X</button>

            <div className={classes.slider}>

                {images.map((item, index) => {
                    return (
                        <div key={index} className={classes.slide} style={{transform: `translateX(${x}%)`}}>
                            <GalleryModalImage src={item} alt={alt}/>
                        </div>
                    )
                })}
                <button className={classes.goLeft} onClick={goLeft}>
                    <div className={`${classes.carouselButtonArrow} ${classes.carouselButtonArrowPrev}`}/>
                </button>
                <button className={classes.goRight} onClick={goRight}>
                    <div className={`${classes.carouselButtonArrow} ${classes.carouselButtonArrowNext}`}/>
                </button>
            </div>
        </StyledGalleryModal>,
        document.getElementById('portal'),
    ) : null;
}

export default GalleryModal;
