import React from 'react';
import {useAuth} from "react-oidc-context";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, TextField, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import type {TechnicianPriceStrategyExtended} from "../../shared/dto/technician-price-strategy-extended";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledTechnicianProfilePriceStrategyAccordion = styled(Box)(({theme}) => ({}));

const TechnicianProfilePriceStrategyAccordion = ({technicianPriceStrategies}: {
    technicianPriceStrategies: TechnicianPriceStrategyExtended[]
}) => {
    const auth = useAuth();

    if (auth.isAuthenticated) {
        return (
            <StyledTechnicianProfilePriceStrategyAccordion>
                {technicianPriceStrategies.map((strategy: TechnicianPriceStrategyExtended) => (
                    <Accordion key={strategy.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={`panel-${strategy.id}-content`}
                            id={`panel-${strategy.id}-header`}
                        >
                            <Typography>{strategy.serviceOrderSubtypeExtendedDto.translation}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{marginBottom: 2}}>
                                <TextField
                                    label="Price"
                                    type="number"
                                    defaultValue={strategy.price}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{marginBottom: 1}}
                                />
                                <TextField
                                    label="Transport Price"
                                    type="number"
                                    defaultValue={strategy.priceTransport}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{marginBottom: 1}}
                                />
                                <TextField
                                    label="Currency"
                                    defaultValue={strategy.currency}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{marginBottom: 1}}
                                />
                                <IconButton color="primary">
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </StyledTechnicianProfilePriceStrategyAccordion>
        );
    }

    return (
        <Typography variant="body1" color="textSecondary">
            User not authenticated. Please log in to view the technician profile.
        </Typography>
    );
};

export default TechnicianProfilePriceStrategyAccordion;
