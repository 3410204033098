import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Divider, Grid, Link, SelectChangeEvent, useMediaQuery} from "@mui/material";
import {classes} from "./appointment-styles";
import styled from "@emotion/styled";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import AppointmentSelectionLine from "./appointment-selection-line/appointment-selection-line";
import {AvailabilityCheck} from "../shared/dto/availability-check";
import {useAppointmentApi} from "../api/appointment-api";
import type {AvailabilityWithTimeSlot} from "../shared/dto/availability-with-time-slot";
import {ServiceOrderContext} from "../../../context/service-order-context/service-order-context";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {AppContext} from "../../../context/app-context/app-context";
//TODO: mhalushka this one should be refactored
import {displayDays} from "../../../context/app-context/util/app-context-recalculate-availability-days";

const StyledAppointment = styled(Box)(({theme}) => ({
    display: 'flex',
    backgroundColor: '#F1F5F6',
    justifyContent: "center",

    [`& .${classes.content}`]: {
        width: "100%",
        paddingRight: "8vw",
        paddingLeft: "8vw",
    },
}));

const AppointmentSingleLine = ({availabilityWithTimeSlots}) => {
    const [availabilityWithTimeSlot: AvailabilityWithTimeSlot, setAvailabilityWithTimeSlot] = useState(availabilityWithTimeSlots[0]);

    useEffect(() => {
        if (availabilityWithTimeSlots !== undefined || availabilityWithTimeSlots !== []) {
            setAvailabilityWithTimeSlot(availabilityWithTimeSlots[0]);
        }
    }, [availabilityWithTimeSlots]);

    const selectDate = (event: SelectChangeEvent) => {
        setAvailabilityWithTimeSlot(event.target.value);
    };

    return (
        <>
            {
                (availabilityWithTimeSlot === undefined || availabilityWithTimeSlot === null) ? <CircularProgress/> :
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <InputLabel id="appointment-selection-date">Date for service</InputLabel>
                        <Select
                            labelId="appointment-selection-date"
                            id="appointment-selection-date-selector"
                            value={availabilityWithTimeSlot}
                            onChange={selectDate}
                            label="Date for service"
                        >
                            {
                                availabilityWithTimeSlots.map((slot, index) => (
                                    <MenuItem value={slot} key={slot.date}>{slot.date}</MenuItem>
                                ))
                            }
                        </Select>
                        {availabilityWithTimeSlot &&
                            <AppointmentSelectionLine availabilityWithTimeSlot={availabilityWithTimeSlot}/>}
                    </FormControl>
            }
        </>

    );
}

const AppointmentDate = () => {

    const {fetchAvailableSlotsForDates} = useAppointmentApi();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const {getAvailabilityDays} = useContext(AppContext);
    const {serviceOrderData} = useContext(ServiceOrderContext);
    const [availabilityWithTimeSlots: AvailabilityWithTimeSlot[], setAvailabilityWithTimeSlots] = useState([]);

    useEffect(() => {
        let availabilityDays = getAvailabilityDays();
        const availabilityCheck: AvailabilityCheck = {
            dates: availabilityDays,
            serviceOrderSubtypeDto: serviceOrderData.serviceOrderSubtypeDto
        }
        fetchAvailableSlotsForDates(availabilityCheck).then((result: AvailabilityWithTimeSlot) => {
            setAvailabilityWithTimeSlots(result);
        });
    }, []);

    if (availabilityWithTimeSlots === undefined || availabilityWithTimeSlots === []) {
        return (<CircularProgress/>)
    }

    return (
        <>
            {!isSmallScreen ?
                availabilityWithTimeSlots.map((slot, index) => (
                    <Grid item xs={12 / displayDays.large}
                          key={slot.date}
                    >
                        <AppointmentSelectionLine availabilityWithTimeSlot={slot}/>
                    </Grid>
                ))
                :
                <AppointmentSingleLine availabilityWithTimeSlots={availabilityWithTimeSlots}/>
            }
        </>
    )
}

const Appointment = () => {
    return (
        <StyledAppointment>
            <Box className={`${classes.content}`}
            >
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link underline="hover" color="inherit" href="/">
                        <Typography variant={"h3"}>Główna</Typography>
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/order"
                    >
                        <Typography variant={"h3"}>Zamówienie</Typography>
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/order/appointment"
                    >
                        <Typography variant={"h3"}>Wybór terminu</Typography>
                    </Link>
                </Breadcrumbs>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Grid container direction="row">
                    <AppointmentDate/>
                </Grid>
            </Box>
        </StyledAppointment>
    );
}

export default Appointment;
