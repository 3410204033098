import React, {useContext} from "react";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";

const StyledActionButtons = styled(Box)(({theme}) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
}));

const ActionButtons = ({
                           showLogin = false,
                           showHome = false,
                           showLogout = false
                       }) => {

    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translatedTechnician = translation.technician;
    const auth = useAuth();
    const navigate = useNavigate();

    const signOutRedirect = () => {
        const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
        const logoutUri = `${window.location.origin}/`;
        const cognitoDomain = `https://eu-central-1tc0zkhtzj.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com`;
        window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
    };

    return (
        <StyledActionButtons>
            {showLogin && (
                <Button variant="contained" size="medium" onClick={() => auth.signinRedirect()}>
                    {translatedTechnician.action.logIn}
                </Button>
            )}
            {showLogout && (
                <Button variant="contained" size="medium" onClick={() => {
                    signOutRedirect();
                    auth.removeUser();
                }}>
                    {translatedTechnician.action.logOut}
                </Button>
            )}
            {showHome && (
                <Button variant="contained" size="medium" onClick={() => navigate("/")}>
                    {translatedTechnician.action.home}
                </Button>
            )}
        </StyledActionButtons>
    );
};

export default ActionButtons;
