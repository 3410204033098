const PREFIX = 'construction-elements-actions';

export const classes = {
    actionsWrapper: `${PREFIX}-actions-wrapper`,
    actionsTitleAndDescription: `${PREFIX}-actions-title-and-description`,
    actionsTitle: `${PREFIX}-actions-title`,
    actionsDescription: `${PREFIX}-actions-description`,
    actionsButtons: `${PREFIX}-actions-buttons`,
    actionsButtonsElement: `${PREFIX}-actions-buttons-element`,
    actionsButtonsElementIcon: `${PREFIX}-actions-buttons-element-icon`,
    actionsButtonsElementText: `${PREFIX}-actions-buttons-element-text`,
};
