import React from "react";
import {styled} from "@mui/material/styles";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledConstructionElementsWorkDescription = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.primary.background,
}));

const ConstructionElementsWorkDescription = ({selectedContent}) => {
    return (
        <StyledConstructionElementsWorkDescription>
            <Typography variant="h2" gutterBottom>
                {selectedContent.title}
            </Typography>
            <Typography
                variant="paragraph"
                component="p"
                gutterBottom
                dangerouslySetInnerHTML={{__html: selectedContent.titleDescription}}
            />
            <List>
                {selectedContent.advantages.map(advantage => {
                    return (
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="paragraph" component={"p"}>
                                    <strong>{advantage}</strong>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )
                })}
            </List>

            <Typography variant="h2" gutterBottom>
                {selectedContent.subtitle}
            </Typography>
            <Typography
                variant="paragraph"
                component="p"
                dangerouslySetInnerHTML={{ __html: selectedContent.subtitleDescription }}
            />
            <List>
                {selectedContent.disadvantagesSolutions.map(disadvantageSolution => {
                    return (
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="paragraph" component={"p"}>
                                    <strong>{disadvantageSolution}</strong>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )
                })}
            </List>
        </StyledConstructionElementsWorkDescription>
    );
};

export default ConstructionElementsWorkDescription;
