import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {classes} from "./contact-input-text-styles";
import {styled} from "@mui/material/styles";
import {alpha} from "@mui/material";
import {colorPalette} from "../../../util/color-palette";

export const StyledContactInputText = styled(Grid, {shouldForwardProp: (prop) => prop !== 'inverseColors'})
(({theme, inverseColors}) => ({

    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        margin: 'auto'
    },
    [theme.breakpoints.down('md')]: {
        margin: 'auto'
    },

    [`& .${classes.inputLabelContainerAlign}`]: {
        [theme.breakpoints.down('lg')]: {
            textAlign: 'center',
            padding: '4px 12px 4px 12px',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
            padding: '12px',
        },
    },

    [`& .${classes.textFieldContainerAlign}`]: {
        [theme.breakpoints.down('lg')]: {
            textAlign: 'center',
            padding: '0px 12px 0px 12px',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
            padding: '12px 0px 12px 12px',
        },
    },

    [`& .${classes.inputLabel}`]: {
        position: 'relative',
        color: inverseColors ? theme.palette.secondary.main : alpha(theme.palette.primary.background, 0.87),
        textAlign: 'right',
        letterSpacing: '2px',
    },

    [`& .${classes.inputLabelTextPadding}`]: {
        paddingTop: 10
    },

}));

const ContactInputText = (props) => {
    const [isInvalid, setIsInvalid] = useState(false);

    const onChange = (event) => {
        const value = event.target.value;
        const invalid = (props.validity?.pattern && !new RegExp(props.validity.pattern).test(value)) ||
            (props.validity?.required && !value) ||
            (props.validity?.maxLength && value.length > props.validity.maxLength);
        setIsInvalid(invalid);
        props.onChange(event);
    };

    return (
        <StyledContactInputText container={true}
                                item
                                inverseColors={props.inverseColors}
        >
            <Grid item xs={12} sm={12} md={3} lg={4} xl={4} className={classes.inputLabelContainerAlign}>
                <Typography variant={"h3"} className={`${classes.inputLabel} ${classes.inputLabelTextPadding}`}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={8} xl={8} className={classes.textFieldContainerAlign}>
                <TextField multiline
                           rows={5}
                           fullWidth
                           id={props.id}
                           value={props.value}
                           onChange={onChange}
                           label={props.inputPlaceholder}
                           placeholder={props.inputPlaceholderMessage}
                           sx={{backgroundColor: colorPalette.background}}
                           variant="filled"
                           margin="dense"
                           error={isInvalid}
                           focused={isInvalid}
                           required={props.validity?.required || false}
                           InputLabelProps={{
                               shrink: true,
                           }}
                />
            </Grid>
        </StyledContactInputText>
    );
}

export default ContactInputText;
