import about_1 from '../asset/about/about_1.jpg';
import about_2 from '../asset/about/about_2.jpg';
import about_3 from '../asset/about/about_3.jpg';
import about_4 from '../asset/about/about_4.jpg';
import about_5 from '../asset/about/about_5.jpg';
import about_6 from '../asset/about/about_6.jpg';
import english from "../asset/home/navigation/english.png";
import polish from "../asset/home/navigation/polish.png";
import commonTranslate from "./translate-common";
import translateCommon from "./translate-common";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import CabinIcon from "@mui/icons-material/Cabin";
import React from "react";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForestIcon from "@mui/icons-material/Forest";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import {Construction} from "@mui/icons-material";

const translate = {
    options: [
        {
            index: 0,
            value: "plPL",
            label: "PL",
            icon: polish
        },
        {
            index: 1,
            value: "enUS",
            label: "EN",
            icon: english
        },
    ],
    enUS: {
        label: 'EN',
        company: {
            title: commonTranslate.enUS.company,
            email: commonTranslate.enUS.email,
            phone: commonTranslate.enUS.phone,
        },
        home: {
            title: "Home",
            heading: "Professional service 24/7: plumbing, electrical, pumps, heating",
            url: "/#home",
            readMore: "Read more",
            action: {
                logIn: translateCommon.enUS.action.logIn,
                myProfile: translateCommon.enUS.action.myProfile,
            },
            company: {
                shortDescription: commonTranslate.enUS.company + ' construction works and heating systems',
                extendedDescription: `
                    We specialize in heating systems and finishing. 
                    We install heat pumps and underfloor heating. 
                    We insulate buildings and help with renovations
                `,
                contactButton: commonTranslate.enUS.action.contactButton,
            },
            header: {
                company: commonTranslate.plPL.company,
                pageMap: 'Page map',
                email: commonTranslate.plPL.email,
            },
            navigation: {
                offer: 'Offer',
                projects: 'Projects',
                contact: 'Contact',
            },
            calculator: {
                currency: commonTranslate.enUS.currency,
                summary: {
                    title: 'Savings calculator:',
                    result: 'Maintenance costs:',
                },
                actions: {
                    title: 'Select available options:',
                    solarPanel: {
                        title: 'Solar panels',
                        description: "Solar panels pomogą zasilić pompę",
                    },
                    heatPump: {
                        title: 'Heat pump',
                        description: "Take care of the house heating through additional thermal insulation",
                    },
                    floorHeating: {
                        title: 'Floor heating',
                        description: "Perfect combination with photovoltaic panels and heat pump",
                    },
                    buildingInsulation: {
                        title: 'Building insulation',
                        description: "The most important piece of the puzzle will allow for maximum savings",
                    }
                },
            },
            carouselContent: [
                {
                    title: 'Solar panels',
                    content: `
                        We specialize in installing photovoltaic panels, switchboards and electric stoves.
                        We assemble on components from companies such as: Solar Edge, Huawei, Gorwatt Solarsofar, Foxess and Hoymiles.
                        And We rely on photovoltaic panels produced by: Sharp, Longi, Canadian Solar, QCell, Jinko and Bifacial.`,
                },
                {
                    title: 'Pure energy',
                    content: `
                        Electricity prices are rising every year, accounting for an increasing proportion of home maintenance costs.
                        With our company, you can cover your energy needs yourself.
                        A well-designed photovoltaic installation reduces the electricity bill almost to zero,
                        at the same time offering the reimbursement of costs incurred in 7-8 years.
                    `,
                },
                {
                    title: 'Building structure',
                    content: `
                        Building structures - construction, superstructures, extensions, adaptations of residential and public buildings in the full range.
                        Replacement and reinforcement of building structures: replacement of ceilings, load-relieving ceilings, lining and strengthening the foundation of buildings.
                    `,
                },
                {
                    title: 'Renovations',
                    content: `
                        Having the design ready, we start the finishing works. Our construction teams,
                        under the supervision of an architect and construction coordinator, they will efficiently implement the interior design.
                        This stage - depending on the size of the investment - will last from one and a half to several months.
                        We know well that a solid base is the basis for the success of any investment, which is why this part is crucial -
                        we take our time and pay great attention to details.
                    `,
                },
                {
                    title: 'Construction supervision',
                    content: `
                        We control investments in terms of compliance of the work performed with the approved project,
                        the conditions defined in the building permit decision and the provisions of the act.
                        Check whether the land development complies with the local spatial development plans and environmental protection requirements.
                        We verify the conditions for the safety of people and property at the stage of design, implementation and maintenance of the property.
                    `,
                }
            ]
        },
        contact: {
            label: translateCommon.enUS.navigation.contact.label,
            url: translateCommon.enUS.navigation.contact.href,
            shortInfo: 'Beaver Plumbers Group',
            info: `
            ul. Główna 1A, 54-061, <strong>Wrocław</strong><br/>
            Tel. +48 537 777 308<br/>
            email: kontakt@beaver-plumbers.com
            `,
            form: {
                name: {
                    label: 'Name',
                    placeholder: 'Name'
                },
                email: {
                    label: 'Email',
                    placeholder: 'Email'
                },
                phone: {
                    label: 'Phone Number',
                    placeholder: 'Phone'
                },
                message: {
                    label: 'Message',
                    placeholder: 'Message',
                    placeholderMessage: 'Please enter a text here...'
                },
                citySelection: {
                    label: 'City or range',
                    placeholder: 'City or range',
                    options: [
                        {
                            value: "Wrocław",
                            label: "Wrocław",
                        },
                        {
                            value: "Wrocław + 30 km",
                            label: "Wrocław + 30 km",
                        }
                    ]
                },
                city: {
                    label: 'City',
                    placeholder: 'City'
                },
                street: {
                    label: 'Street',
                    placeholder: 'Street'
                },
                postalCode: {
                    label: 'Postal Code',
                    placeholder: 'Postal Code'
                },
                house: {
                    label: 'House',
                    placeholder: 'House'
                },
                houseNumber: {
                    label: 'House Number',
                    placeholder: 'House Number'
                },
                privacyPolicy: {
                    consent: {
                        title: "I agree to the terms",
                        titleLink: "Privacy Policy",
                    },
                    policy: {
                        title: "Privacy Policy Terms and Conditions",
                        content: `
                            <p>Effective Date: <strong>01.01.2025</strong></p>
                
                            <section>
                                <h3>1. Data Administrator</h3>
                                <p>The administrator of your personal data is <strong>Beaver Plumbers Group</strong>. If you have any questions, you can contact us:</p>
                                <ul>
                                    <li>Email: [contact@beaver-plumbers.com]</li>
                                    <li>Phone: [535551885]</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>2. Scope of Data Collection</h3>
                                <p>Your personal data is collected in the following circumstances:</p>
                                <ul>
                                    <li>When you contact us via email, phone, or online forms.</li>
                                    <li>When you enter into an agreement or transaction with us.</li>
                                    <li>When you subscribe to newsletters or marketing communications.</li>
                                </ul>
                                <p>Collected data may include:</p>
                                <ul>
                                    <li>Name and surname</li>
                                    <li>Address</li>
                                    <li>Email address</li>
                                    <li>Phone number</li>
                                    <li>Information related to inquiries or transactions</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>3. Purpose and Legal Basis for Data Processing</h3>
                                <p>Your data is processed for the following purposes:</p>
                                <ul>
                                    <li>Fulfillment of contracts and processing inquiries (Article 6(1)(b) of GDPR).</li>
                                    <li>Compliance with legal obligations (Article 6(1)(c) of GDPR).</li>
                                    <li>Legitimate interests, such as marketing or fraud prevention (Article 6(1)(f) of GDPR).</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>4. Sharing of Personal Data</h3>
                                <p>Your data may be shared with:</p>
                                <ul>
                                    <li>Service providers contracted by the company.</li>
                                    <li>Legal or regulatory authorities when required by law.</li>
                                    <li>Other entities only with your explicit consent.</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>5. Data Retention</h3>
                                <p>Data is stored only for the necessary period to fulfill its purposes or as required by law:</p>
                                <ul>
                                    <li>Transactional data is retained for 1 month.</li>
                                    <li>Contact information for marketing purposes is retained until consent is withdrawn.</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>6. Your Rights</h3>
                                <p>You have the right to:</p>
                                <ul>
                                    <li>Access your personal data.</li>
                                    <li>Correct inaccurate data.</li>
                                    <li>Request deletion of data if applicable.</li>
                                    <li>Restrict or object to data processing.</li>
                                    <li>Receive a copy of your data in a machine-readable format.</li>
                                    <li>Withdraw consent at any time.</li>
                                    <li>File a complaint with a data protection authority.</li>
                                </ul>
                            </section>
                
                            <section>
                                <h3>7. Data Protection</h3>
                                <p>We implement technical and organizational measures to ensure data security, including encryption and access control.</p>
                            </section>
                
                            <section>
                                <h3>8. Cookies and Tracking Technologies</h3>
                                <p>Our website uses cookies and similar technologies for analytics, functionality, and marketing purposes.</p>
                            </section>
                
                            <section>
                                <h3>9. Changes to the Policy</h3>
                                <p>This privacy policy may be updated to reflect legal or operational changes. The latest version will always be available on our website.</p>
                            </section>
                
                            <section>
                                <h3>10. Contact Information</h3>
                                <p>For matters related to this policy, please contact us:</p>
                                <ul>
                                    <li>Email: [contact@beaver-plumbers.com]</li>
                                    <li>Phone: [535551885]</li>
                                </ul>
                            </section>
                        `,
                    }
                },
                sendButton: {
                    label: 'Send',
                    placeholder: 'Send'
                },
                orderButton: commonTranslate.enUS.action.contactButton,
            },
            action: {
                email: {
                    success: 'Email sent successfully',
                    error: 'Error sending email: '
                },
                order: {
                    success: 'Order created successfully',
                }
            }
        },
        about: {
            title: 'About Us',
            url: '/#about',
            content: `
            Beaver Plumbers is a Polish Company that serves clients and workers in Construction, Building projects and Clean Energy. 
            We have built local professional teams our business spreads across the whole Poland.
            `,
            learnMore: 'Learn More',
            cardContent: [
                {
                    title: 'Renewable energy',
                    content: `
                    Renewable energy is energy that is collected from renewable resources, 
                    which are naturally replenished on a human timescale, including carbon neutral sources like sunlight, wind, rain, tides, 
                    waves, and geothermal heat. The term often also encompasses biomass as well.
                    `,
                    img: about_1,
                },
                {
                    title: 'Solar Panel',
                    content: `
                    The term solar panel is used colloquially for a photo-voltaic (PV) module.
                    A PV module is an assembly of photo-voltaic cells mounted in a framework for installation. 
                    Photo-voltaic cells use sunlight as a source of energy and generate direct current electricity. 
                    `,
                    img: about_2,
                },
                {
                    title: 'House planing',
                    content: `
                    A house plan is a set of construction or working drawings (sometimes called blueprints) 
                    that define all the construction specifications of a residential house such as the dimensions, 
                    materials, layouts, installation methods and techniques.
                    `,
                    img: about_3
                },
                {
                    title: 'Electricity',
                    content: `
                    Electricity is the set of physical phenomena associated with the presence and motion of matter that has a property of electric charge. 
                    Electricity is related to magnetism, both being part of the phenomenon of electromagnetism, as described by Maxwell's equations. 
                    `,
                    img: about_4
                },
                {
                    title: 'Renovation',
                    content: `
                    We’ve built relationships with the best, local contractors in your area. 
                    They’re 100% licensed and insured–and they’ll provide milestone updates throughout the build.
                    `,
                    img: about_5
                },
                {
                    title: 'Heating pump',
                    content: `
                    We offer advice on choosing the most optimal heat source in your home or business. 
                    Whether with heating foils, which keep the floor warm, or with infrared heaters, which are the easiest to install.
                    `,
                    img: about_6
                },
            ]
        },
        service: {
            title: 'Service',
            url: '/#service',
            content: `
            Meet our service offer. You can easily choose time when it fits your personal requirements
            `,
            submit: 'Submit',
            timeLine: [
                {
                    title: 'Choose date',
                    content: 'Select appropriate for you date of service',
                    icon: 'calendar',
                },
                {
                    title: 'Write a description',
                    content: 'Name the service event and provide contact information',
                    icon: 'create',
                },
                {
                    title: 'Accept policies',
                    content: 'Submit service event and accept our policies',
                    icon: 'check',
                },
                {
                    title: 'Wait for our contact',
                    content: 'Grab a coffee and wait untill we contact you',
                    icon: 'call',
                },
            ]
        },
        gallery: {
            title: translateCommon.enUS.navigation.gallery.label,
            url: translateCommon.enUS.navigation.gallery.href,
            header: 'Explore our projects: 24/7 service, plumbing, and electrical work. From clogged sinks to heat pump installations.',
            headerDescription: `Discover our latest projects completed across Poland, including <strong>heat pump installations</strong>, <strong>underfloor heating</strong>, 
                                <strong>heating systems</strong>, <strong>24/7 service</strong>, <strong>plumbing emergency repairs</strong>, <strong>fault fixing</strong>, <strong>pipe unblocking</strong>, 
                                and <strong>electrical repairs</strong>. Each project represents a unique and <strong>carefully</strong> executed investment, 
                                <strong>guaranteeing reliability</strong> and the <strong>highest quality</strong> <strong>services</strong> provided by Beaver Plumbers Group.`,
            deliveryTime: 'Delivery time:',
            workArea: 'Work area:',
            galleryContent: [
                {
                    title: 'Wrocław - Pipe Cleaning',
                    content: '<strong>Professional Pipe Cleaning</strong> and <strong>Blockage Removal</strong>',
                    textSubTitle: '<strong>Fast and Effective Service</strong> for Clogged Pipes in Wrocław',
                    description: `In this project, we performed <strong>professional pipe cleaning</strong> in an office building in Wrocław. 
                                  Using advanced technologies, we ensured <strong>effective blockage removal</strong> and restored 
                                  <strong>smooth operation</strong> to the hydraulic system. Thanks to our <strong>24/7 service</strong>, 
                                  the client was able to resolve their plumbing issue promptly. We specialize in comprehensive services, 
                                  including <strong>plumbing maintenance</strong>, <strong>pipe unclogging</strong>, and <strong>drain cleaning</strong>. 
                                  During this project, we also identified potential risks in the system and recommended preventive measures 
                                  to avoid future issues. Our professional solutions ensured that the office regained full functionality, 
                                  providing a comfortable environment for all occupants.
                    `,
                    alt: `Pipe cleaning in Wrocław - Blockage removal, drain unclogging, and plumbing service. 
                          Professional pipe cleaning ensuring smooth operation of the plumbing system in an office building.
                          Unclogging pipes, drain cleaning, and hydraulic maintenance. Comprehensive pipe cleaning solutions for office buildings.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g2/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g2/2.jpg`,
                    ],
                    deliveryTime: '1 hour',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `10 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Warsaw - Heat Pump Installation',
                    content: '<strong>Modern Heat Pump Installation</strong> for <strong>Energy Savings</strong>',
                    textSubTitle: '<strong>Efficient Heating</strong> and <strong>Cooling Solutions</strong> for a Single-Family Home in Warsaw',
                    description: `In this project, we installed a <strong>modern air-to-water heat pump</strong>, designed to provide 
                                  <strong>energy-efficient heating and cooling</strong> for a single-family home in Warsaw. 
                                  The system was <strong>seamlessly integrated</strong> with the existing heating infrastructure, 
                                  ensuring <strong>year-round thermal comfort</strong> and <strong>significant energy savings</strong>. 
                                  This solution is ideal for homeowners seeking to reduce energy costs and their carbon footprint.
                    `,
                    alt: `Heat Pump Installation in Warsaw - Modern air-to-water system ensuring energy efficiency, 
                          thermal comfort, and year-round heating and cooling for single-family homes.`,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g1/0.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g1/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/2.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/3.jpg`,
                    ],
                    deliveryTime: '2 weeks',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `25 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Szczecin - Heating System Repair',
                    content: '<strong>Heating System Repair</strong> and <strong>Plumbing Service</strong>',
                    textSubTitle: '<strong>Fast Assistance</strong> for Heating System Issues in Szczecin',
                    description: `In this project, we carried out a <strong>heating system repair</strong> for a hotel in Szczecin. 
                                  Our team performed a thorough diagnosis and resolved faults in the hydraulic installation, 
                                  restoring the <strong>full functionality</strong> of the system. Thanks to our <strong>24/7 service</strong>, 
                                  the hotel was able to quickly address the issue without impacting guest comfort. 
                                  We offer comprehensive services, including <strong>plumbing repairs</strong>, <strong>pipe cleaning</strong>, 
                                  and <strong>heating system maintenance</strong>. Additionally, we implemented improvements to reduce the 
                                  risk of future breakdowns, ensuring long-term efficiency and reliability.
                    `,
                    alt: `Heating system repair in Szczecin - Fault resolution in hydraulic installations, professional heating system maintenance, 
                          and fast response with 24/7 service. Comprehensive repair and optimization for hotels.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g3/0.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g3/1.jpg`,
                    ],
                    deliveryTime: '3 days',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `30 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wałbrzych - Underfloor Heating Repair',
                    content: '<strong>Underfloor Heating Repair</strong> and <strong>Heating System Maintenance</strong>',
                    textSubTitle: '<strong>Fast Assistance</strong> for Underfloor Heating Issues in Wałbrzych',
                    description: `In this project, we addressed an <strong>underfloor heating repair</strong> in a production hall in Wałbrzych. 
                                  Our team conducted a thorough diagnosis of the system powered by an air-to-water heat pump, identifying 
                                  the root cause of the issue. Thanks to our <strong>plumbing service</strong> and <strong>24/7 support</strong>, 
                                  the fault was quickly resolved, and the system was restored to full functionality. 
                                  We specialize in comprehensive services, including <strong>underfloor heating repair</strong>, 
                                  <strong>leak detection and resolution</strong>, and <strong>hydraulic system cleaning</strong>. 
                                  Preventive measures were also implemented to minimize the risk of future problems, optimizing costs and 
                                  ensuring a comfortable working environment for employees.
                    `,
                    alt: `Underfloor heating repair in Wałbrzych - Resolving faults in systems powered by heat pumps, 24/7 service, 
                          hydraulic system maintenance, and optimization of heating system performance.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g4/11.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g4/12.jpg`,
                    ],
                    deliveryTime: '5 days',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `70 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdańsk - Heat Pump Service',
                    content: '<strong>Heat Pump Repair</strong> and <strong>Maintenance</strong> for Public Buildings',
                    textSubTitle: '<strong>Fast and Reliable Service</strong> for Heat Pumps in Gdańsk',
                    description: `In this project, we provided <strong>heat pump service</strong> in a public utility building in Gdańsk. 
                                  Our team conducted a thorough diagnosis and performed <strong>maintenance and repairs</strong> to restore 
                                  the full functionality of the system. Thanks to our <strong>24/7 service</strong>, the job was completed 
                                  quickly, ensuring uninterrupted operation of the facility. We specialize in comprehensive services, 
                                  including <strong>heat pump maintenance</strong>, <strong>hydraulic system optimization</strong>, 
                                  and <strong>resolving performance issues</strong>. Additionally, we implemented system improvements 
                                  that enhanced its efficiency and reduced energy costs, benefiting both the environment and the building’s budget.
                    `,
                    alt: `Heat pump service in Gdańsk - Maintenance and repair of heating systems, diagnostics, hydraulic service, 
                          and optimization of heat pump performance in public buildings.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g5/14.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '3 days',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdynia - Heat Pump Service in a Hotel',
                    content: '<strong>Heat Pump Repair</strong> and <strong>Maintenance</strong> for Hotels',
                    textSubTitle: '<strong>Fast and Professional Service</strong> for Heat Pumps in Gdynia',
                    description: `In this project, we provided <strong>heat pump service</strong> for a hotel in Gdynia. 
                                  Our team conducted detailed diagnostics and performed <strong>repairs and maintenance</strong> on the system, 
                                  restoring its full functionality. With our services, the hotel once again offers guests the ability to 
                                  <strong>independently control room temperatures</strong>, while significantly <strong>reducing energy costs</strong>. 
                                  We specialize in comprehensive services, including <strong>heat pump repair</strong>, 
                                  <strong>hydraulic system maintenance</strong>, and <strong>optimization of heating system performance</strong>. 
                                  Additionally, we implemented improvements to the system, reducing the hotel’s operational expenses.
                    `,
                    alt: `Heat pump service in Gdynia - Repair and maintenance of heating systems in hotels, 
                          heat pump diagnostics, performance optimization, and professional hydraulic services.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '4 days',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `40 m<sup>2</sup>`}}/>,
                },
            ]
        },
        offer: {
            title: translateCommon.enUS.navigation.offer.label,
            content: 'Meet our offer',
            url: commonTranslate.enUS.navigation.offer.href,
            contactButton: commonTranslate.enUS.action.contactButton,
            actions: {
                title: 'ORDER SELECTED SERVICES FROM OUR OFFER',
                description: `Explore our wide range of services, including <strong>professional 24/7 service</strong>, 
                              <strong>solar panel installation</strong>, <strong>heat pump installation</strong>, 
                              and <strong>modern underfloor heating</strong>. Our solutions are designed with <strong>energy efficiency</strong>, 
                              <strong>cost reduction</strong>, and <strong>everyday comfort</strong> in mind. 
                              Discover how our services can enhance your home's value and improve its energy efficiency.`,
                buttons: {
                    SERVICE_24_7: {
                        action: `SERVICE_24_7`,
                        name: `Service 24/7`,
                        icon: <Construction/>,
                        image: `/assets/service_24_7.png`,
                    },
                    SOLAR_PANELS: {
                        action: `SOLAR_PANELS`,
                        name: `Solar panels`,
                        icon: <SolarPowerIcon/>,
                        image: `/assets/solar_panel.png`,
                    },
                    HEAT_PUMP: {
                        action: `HEAT_PUMP`,
                        name: `Heat pump`,
                        icon: <HeatPumpIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    UNDERFLOOR_HEATING: {
                        action: `UNDERFLOOR_HEATING`,
                        name: `Underfloor heating`,
                        icon: <CabinIcon/>,
                        image: `/assets/floor_heating.png`,
                    }
                },
                content: {
                    title: 'ESTIMATED COST ANALYSIS FOR HEAT PUMP IN 2024',
                    elements: {
                        SOLAR_PANELS: {
                            title: `Best Solar Panels - Top Sustainable Energy Solution`,
                            titleDescription: `Solar panels are an innovative way to power your home or business, 
                                               providing <strong>affordable solar energy solutions</strong> 
                                               while reducing energy costs and minimizing environmental impact. 
                                               <strong>Key Benefits of Solar Panels:</strong>`,
                            advantages: [
                                `Significant reduction in electricity bills.`,
                                `Environmentally friendly and reduces carbon footprint.`,
                                `Low maintenance and long lifespan.`,
                                `Increases property value.`,
                                `Eligibility for tax credits and rebates.`,
                            ],
                            subtitle: `Overcoming Solar Panel Installation Challenges`,
                            subtitleDescription: `While solar panels are an excellent choice, it's important to consider factors such as 
                                                  <strong>upfront installation costs</strong>, <strong>dependence on sunlight</strong>, 
                                                  and <strong>the space required for installation</strong>. 
                                                  However, we can help address these challenges:`,
                            disadvantagesSolutions: [
                                `Flexible financing options to ease upfront costs.`,
                                `Professional installation ensuring optimal performance even in limited sunlight.`,
                                `Custom designs to fit available space efficiently.`,
                            ],
                        },
                        BUILDING_INSULATION: {
                            title: `Building Insulation`,
                            titleDescription: ``,
                            advantages: [
                                ``,
                            ],
                            subtitle: `Overcoming Building Insulation Installation Challenges`,
                            subtitleDescription: ``,
                            disadvantagesSolutions: [
                                ``,
                            ],
                        },
                        HEAT_PUMP: {
                            action: `HEAT_PUMP`,
                            name: `Heat pump`,
                            icon: <HeatPumpIcon/>,
                            image: `/assets/heat_pump.png`,
                        },
                        UNDERFLOOR_HEATING: {
                            title: `Efficient Floor Heating - Maximum Comfort and Energy Savings`,
                            titleDescription: `Floor heating provides a <strong>comfortable and efficient heating solution</strong>, 
                                               ensuring evenly distributed warmth throughout your home or office. 
                                               <strong>Key Benefits of Floor Heating:</strong>`,
                            advantages: [
                                `Even distribution of heat across the room for enhanced comfort and energy efficiency.`,
                                `Invisible installation allows maximum use of space.`,
                                `Compatible with various energy sources, including heat pumps and solar panels.`,
                                `Reduces dust and allergens compared to traditional radiators.`,
                                `Silent operation with no noise from heating devices.`,
                            ],
                            subtitle: `Overcoming Floor Heating Installation Challenges`,
                            subtitleDescription: `While floor heating offers many benefits, some challenges may arise, such as 
                                                  <strong>higher initial costs</strong>, <strong>time-consuming installation</strong>, and 
                                                  <strong>difficulties in retrofitting existing floors</strong>. 
                                                  However, these challenges can be addressed effectively:`,
                            disadvantagesSolutions: [
                                `Flexible financing options to make the initial investment more manageable.`,
                                `A professional installation team ensures quick and efficient setup.`,
                                `Customized project designs tailored to the existing technical conditions.`,
                            ],
                        }
                    },
                    serviceOffer: {
                        orderAction: `${commonTranslate.enUS.action.check}`,
                        title: `Professional Plumbing and Heating Services Available 24/7`,
                        description: `We provide a <strong>comprehensive range of expert plumbing and heating services</strong>,
                                      including <strong>24/7 emergency support</strong>, <strong>pipe cleaning</strong>, and <strong>leak detection</strong>. 
                                      Our solutions are tailored for both <strong>residential and commercial
                                      properties</strong> to ensure reliability and <strong>cost-effectiveness</strong>.
                                      Explore our easy-to-navigate categories for seamless service selection.`,
                    },
                    data: [
                        {
                            title: 'Completion time',
                            icon: <QueryBuilderIcon fontSize={"inherit"}/>,
                            description: '2 weeks'
                        },
                        {
                            title: 'Surface area',
                            icon: <HomeIcon fontSize={"inherit"}/>,
                            description: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>
                        },
                        {
                            title: 'Heating period',
                            icon: <CalendarMonthIcon fontSize={"inherit"}/>,
                            description: '1 month',
                        }
                    ],
                    table: {
                        tableTitle: "Cost Comparison",
                        tableHeader: [
                            "Fuel Type",
                            "Usable Area",
                            "Unit Consumption",
                            "Cost (PLN/month)"
                        ],
                        fuelVariants: [
                            {
                                icon: <HeatPumpIcon/>,
                                fuelName: "Heat Pump",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "value",
                                price: 41
                            },
                            {
                                icon: <ForestIcon/>,
                                fuelName: "Firewood",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "1 ton",
                                price: 450
                            },
                            {
                                icon: <OutdoorGrillIcon/>,
                                fuelName: "Coal",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "150 kg",
                                price: 600
                            },
                            {
                                icon: <PropaneTankIcon/>,
                                fuelName: "Natural Gas",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: <span dangerouslySetInnerHTML={{__html: `70 m<sup>3</sup>`}}/>,
                                price: 170
                            },
                            {
                                icon: <FlashOnIcon/>,
                                fuelName: "Electricity",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "120 kWh",
                                price: 210
                            }
                        ]
                    }
                },
                summary: `Below, we present detailed cost calculations for a single-family house with a usable area of 50&nbsp;m<sup>2</sup>, 
                          based on <strong>current energy prices</strong> and parameters for the year 2024. To learn more 
                          about <strong>efficient heating</strong>  and <strong>reducing operational costs</strong> with <strong>heat pump</strong> 
                          solutions, contact us today. We offer <strong>personalized consultations</strong> and <strong>modern heating solutions</strong> 
                          tailored to your needs.`,
                noAction: 'No actions available. Please define action buttons.'
            }
        },
        footer: {
            products: {
                title: 'Products',
                solarPanel: {
                    title: 'Solar Panels',
                    url: commonTranslate.enUS.navigation.offer.href,
                },
                buildingInsulation: {
                    title: 'Building Insulation',
                    url: commonTranslate.enUS.navigation.offer.href,
                },
                heatPump: {
                    title: 'Heat pump',
                    url: commonTranslate.enUS.navigation.offer.href,
                },
                floorHeating: {
                    title: 'Floor heating',
                    url: commonTranslate.enUS.navigation.offer.href,
                },
            },
            information: {
                title: 'Information',
                privacyPolicy: {
                    title: 'Privacy policy',
                    url: '#',
                },
                license: {
                    title: 'License',
                    url: '#',
                },
                faq: {
                    title: 'FAQ',
                    url: '#',
                },
            },
            about: {
                title: 'About',
            },
            subscribe: {
                label: 'Subscribe',
            },
            address: {
                label: 'ul. Rynek 1, 00-000, Wrocław - Beaver Plumbers -',
            },
            termsAndConditions: {
                label: 'Terms & Conditions'
            },
            privacyPolicy: {
                label: 'Privacy Policy'
            },
            sitemap: {
                label: 'Sitemap'
            },
            designedBy: {
                label: 'Designed by Maksim Halushka & Mateusz Szyprowski'
            },
            contactUs: {
                label: 'Contact Us'
            }
        },
        banner: {
            info: 'Site is under construction. Please contact us:',
            phone: commonTranslate.enUS.phone,
        },
        technician: {
            label: 'Technician',
            url: '/technician',
            shortInfo: 'Technician profile',
            token: {
                action: {
                    show: `${commonTranslate.enUS.action.show}`,
                    close: `${commonTranslate.enUS.action.close}`,
                }
            },
            action: {
                home: `${commonTranslate.enUS.action.home}`,
                logOut: `${commonTranslate.enUS.action.logOut}`,
                logIn: `${commonTranslate.enUS.action.logIn}`,
            }
        }
    },
    plPL: {
        label: 'PL',
        company: {
            title: commonTranslate.plPL.company,
            email: commonTranslate.plPL.email,
            phone: commonTranslate.plPL.phone,
        },
        home: {
            title: "Główna",
            heading: "Profesjonalne usługi serwisowe 24/7: hydraulika, elekrtyka, pompy, ogrzewanie",
            url: "/#home",
            readMore: "Czytaj więcej",
            action: {
                logIn: translateCommon.plPL.action.logIn,
                myProfile: translateCommon.plPL.action.myProfile,
            },
            company: {
                shortDescription: commonTranslate.plPL.company + ' profesjonalne wykończenie i  systemy ogrzewania',
                extendedDescription: `
                    Specjalizujemy się w systemach ogrzewania oraz wykończeniach. 
                    Zajmujemy się instalacją pomp ciepła i ogrzewania podłogowego. 
                    Docieplamy budynki i pomagamy w remontach
                `,
                contactButton: commonTranslate.plPL.action.contactButton,
            },
            header: {
                company: commonTranslate.plPL.company,
                pageMap: 'Mapa strony',
                email: commonTranslate.plPL.email,
            },
            navigation: {
                offer: 'Oferta',
                projects: 'Realizacje',
                contact: 'Kontakt',
            },
            calculator: {
                currency: commonTranslate.plPL.currency,
                summary: {
                    title: 'Kalkulator oszczędności ciepła:',
                    result: 'Koszty utrzymania:',
                },
                actions: {
                    title: 'Wybierz interesujące opcje na rysunku:',
                    solarPanel: {
                        title: 'Panele fotowoltaiczne',
                        description: "Panele fotowoltaiczne pomogą zasilić pompę",
                    },
                    heatPump: {
                        title: 'Pompa ciepła',
                        description: "Zadbaj o ciepło budynku poprzez dodatkową izolację cieplną",
                    },
                    floorHeating: {
                        title: 'Ogrzewanie podłogowe',
                        description: "Idealne połączenie z panelami fotowoltaicznymi i pompą ciepła",
                    },
                    buildingInsulation: {
                        title: 'Dodatkowe ocieplenie ścian',
                        description: "Najważniejszy element układanki, pozwoli na maksimum oszczędności",
                    }
                },
            },
            carouselContent: [
                {
                    title: 'Panele fotowoltaiczne',
                    contentShort: `
                        Panele fotowoltaiczne zamieniają energię słoneczną w prąd przez ogniwa fotowoltaiczne, 
                        konwertując go na prąd zmienny za pomocą inwertera.
                    `,
                    content: `
                        Panele fotowoltaiczne, zwane modułami PV, są kluczowym elementem w generowaniu energii elektrycznej z promieniowania słonecznego. 
                        Składają się z wielu ogniw fotowoltaicznych zamontowanych w ramie, przetwarzają światło słoneczne w 
                        energię elektryczną poprzez efekt fotowoltaiczny. Ogniwa te generują prąd stały z fotonów światła słonecznego, 
                        który jest konwertowany na prąd zmienny przez inwerter. Panele są wydajne, trwałe i łatwe w instalacji, znajdują zastosowanie w 
                        domach i elektrowniach. Korzyści to m.in. redukcja emisji CO2, oszczędność na rachunkach i niezależność energetyczna. 
                        Montaż przyczynia się także do wzrostu wartości nieruchomości i propagowania zrównoważonego stylu życia. 
                        Rosnąca zaawansowanie i dostępność technologii fotowoltaicznych kierują ku bardziej zrównoważonej przyszłości energetycznej.
                    `,
                },
                {
                    title: 'Ocieplenie budynku',
                    contentShort: `
                        Ocieplenie budynku to klucz dla efektywności energetycznej i komfortu termicznego, 
                        redukując utratę ciepła, koszty energii oraz utrzymując przyjemną temperaturę.
                    `,
                    content: `
                        Ocieplenie budynku ma kluczowe znaczenie dla efektywności energetycznej i komfortu termicznego. 
                        Poprawiając izolację termiczną ścian, dachu i fundamentów, możliwe jest znaczne zmniejszenie utraty ciepła i zapewnienie stabilnej temperatury we wnętrzu. 
                        Działania te przyczyniają się do efektywnej redukcji kosztów związanych z zużyciem energii, 
                        co ma istotny wpływ na budżet domowy. Dodatkowo, dobrze ocieplony budynek pozwala na utrzymanie odpowiedniego poziomu ciepła 
                        bez nadmiernego zużycia ogrzewania, co jest korzystne zarówno dla środowiska, jak i wygody mieszkańców.
                    `,
                },
                {
                    title: 'Pompa ciepła',
                    contentShort: `
                        Pompa ciepła to nowoczesne źródło ciepła dla budynków i wody, efektywna i ekologiczna, 
                        obniżająca koszty ogrzewania i emisję gazów, idealna dla nowych i istniejących budynków.
                    `,
                    content: `
                        Pompa ciepła to nowoczesne rozwiązanie grzewcze, wykorzystujące energię z otoczenia do produkcji ciepła. 
                        Działa na zasadzie przekształcania niskiej temperatury otoczenia na wyższą temperaturę, 
                        która może być używana do ogrzewania budynków oraz wody użytkowej. Pompy ciepła są efektywne i ekologiczne, 
                        ponieważ wymagają niewielkiej ilości energii elektrycznej do działania. Mogą być wykorzystywane zarówno w nowo budowanych, 
                        jak i istniejących budynkach, przyczyniając się do obniżenia kosztów ogrzewania i emisji gazów cieplarnianych. 
                    `,
                },
                {
                    title: 'Ogrzewanie podłogowe',
                    contentShort: `
                        Ogrzewanie podłogowe to innowacyjna metoda z wodnym krążeniem w podłodze, 
                        gwarantująca równomierne ogrzewanie i efektywność. Idealne jako główne źródło ciepła lub uzupełnienie grzejników.
                    `,
                    content: `
                        Ogrzewanie podłogowe to innowacyjna metoda zapewnienia komfortu termicznego w pomieszczeniach. 
                        Działa poprzez przewodnictwo cieplne, gdzie woda grzejna krąży w specjalnych rurach w podłodze. 
                        To niskotemperaturowe rozwiązanie ma szereg zalet, m.in. równomierne rozprowadzenie ciepła, 
                        brak widocznych elementów grzewczych oraz oszczędność energii dzięki niższym temperaturom pracy. 
                        Ogrzewanie podłogowe może być głównym źródłem ciepła lub uzupełnieniem dla tradycyjnych grzejników, 
                        tworząc efekt ciepłej podłogi, który jest szczególnie przyjemny w chłodniejsze dni.
                    `,
                },
            ]
        },
        contact: {
            label: translateCommon.plPL.navigation.contact.label,
            url: translateCommon.plPL.navigation.contact.href,
            shortInfo: 'Beaver Plumbers Group',
            info: `
            ul. Główna 1A, 54-061, <strong>Wrocław</strong><br/>
            Tel. +48 537 777 308<br/>
            email: kontakt@beaver-plumbers.com
            `,
            form: {
                name: {
                    label: 'Imię',
                    placeholder: 'Imię'
                },
                email: {
                    label: 'Adres Email',
                    placeholder: 'Adres Email'
                },
                phone: {
                    label: 'Numer telefonu',
                    placeholder: 'Numer telefonu'
                },
                message: {
                    label: 'Treść wiadomości',
                    placeholder: 'Treść wiadomości',
                    placeholderMessage: 'Proszę opisać treść zgłoszenia...'
                },
                citySelection: {
                    label: 'Miasto lub okolice',
                    placeholder: 'Miasto lub okolice',
                    options: [
                        {
                            value: "Wrocław",
                            label: "Wrocław",
                        },
                        {
                            value: "Wrocław + 30 km",
                            label: "Wrocław + 30 km",
                        }
                    ]
                },
                city: {
                    label: 'Miasto',
                    placeholder: 'Miasto'
                },
                street: {
                    label: 'Ulica',
                    placeholder: 'Ulica'
                },
                postalCode: {
                    label: 'Kod pocztowy',
                    placeholder: 'Kod pocztowy'
                },
                house: {
                    label: 'Numer domu',
                    placeholder: 'Numer domu'
                },
                houseNumber: {
                    label: 'Numer mieszkania',
                    placeholder: 'Numer mieszkania'
                },
                privacyPolicy: {
                    consent: {
                        title: "Zgadzam się na postanowienia",
                        titleLink: "Polityki Prywatności",
                    },
                    policy: {
                        title: 'Postanowienia Polityki Prywatności',
                        content: `
                            <p>Data wejścia w życie: <strong>01.01.2025</strong></p>

                            <section>
                                <h3>1. Administrator Danych</h3>
                                <p>Administratorem Twoich danych osobowych jest <strong>Beaver Plumbers Group</strong>. W
                                    razie pytań możesz się z nami skontaktować:</p>
                                <ul>
                                    <li>Email: [kontakt@beaver-plumbers.com]</li>
                                    <li>Telefon: [535551885]</li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>2. Zakres Zbierania Danych</h3>
                                <p>Twoje dane osobowe są zbierane w następujących przypadkach:</p>
                                <ul>
                                    <li>Gdy kontaktujesz się z nami przez email, telefon lub formularze online.</li>
                                    <li>Gdy zawierasz z nami umowę lub transakcję.</li>
                                    <li>Gdy zapisujesz się na newslettery lub komunikację marketingową.</li>
                                </ul>
                                <p>Zbierane dane mogą obejmować:</p>
                                <ul>
                                    <li>Imię i nazwisko</li>
                                    <li>Adres</li>
                                    <li>Adres email</li>
                                    <li>Numer telefonu</li>
                                    <li>Informacje dotyczące zapytań lub transakcji</li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>3. Cel i Podstawa Przetwarzania Danych</h3>
                                <p>Twoje dane są przetwarzane w następujących celach:</p>
                                <ul>
                                    <li>Realizacja umów i przetwarzanie zapytań (art. 6 ust. 1 lit. b RODO).</li>
                                    <li>Wypełnienie obowiązków prawnych (art. 6 ust. 1 lit. c RODO).</li>
                                    <li>Prawnie uzasadnione interesy, takie jak marketing czy zapobieganie oszustwom (art. 6
                                        ust. 1 lit. f RODO).
                                    </li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>4. Udostępnianie Danych Osobowych</h3>
                                <p>Twoje dane mogą być udostępniane:</p>
                                <ul>
                                    <li>Podmiotom świadczącym usługi na zlecenie firmy.</li>
                                    <li>Organom prawnym lub regulacyjnym, gdy wymagają tego przepisy prawa.</li>
                                    <li>Innym podmiotom wyłącznie za Twoją wyraźną zgodą.</li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>5. Przechowywanie Danych</h3>
                                <p>Dane przechowywane są wyłącznie przez okres niezbędny do realizacji celów lub wymagany
                                    przepisami prawa:</p>
                                <ul>
                                    <li>Dane transakcyjne przechowujemy przez 1 miesiąc.</li>
                                    <li>
                                        Informacje kontaktowe w celach marketingowych przechowywane są do momentu wycofania
                                        zgody.
                                    </li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>6. Twoje Prawa</h3>
                                <p>Masz prawo do:</p>
                                <ul>
                                    <li>Dostępu do swoich danych osobowych.</li>
                                    <li>Sprostowania nieprawidłowych danych.</li>
                                    <li>Usunięcia danych, jeśli to możliwe.</li>
                                    <li>Ograniczenia lub sprzeciwu wobec przetwarzania danych.</li>
                                    <li>Otrzymania kopii swoich danych w formacie do odczytu maszynowego.</li>
                                    <li>Cofnięcia zgody w dowolnym momencie.</li>
                                    <li>Złożenia skargi do organu ochrony danych.</li>
                                </ul>
                            </section>
    
                            <section>
                                <h3>7. Ochrona Danych</h3>
                                <p>Wdrażamy środki techniczne i organizacyjne zapewniające bezpieczeństwo danych, w tym
                                    szyfrowanie i kontrolę dostępu.</p>
                            </section>
    
                            <section>
                                <h3>8. Pliki Cookie i Technologie Śledzące</h3>
                                <p>Nasza strona korzysta z plików cookie i podobnych technologii w celach analitycznych,
                                    funkcjonalnych i marketingowych.</p>
                            </section>
    
                            <section>
                                <h3>9. Zmiany w Polityce</h3>
                                <p>Polityka prywatności może być aktualizowana w celu odzwierciedlenia zmian prawnych lub
                                    operacyjnych. Najnowsza wersja zawsze będzie dostępna na naszej stronie.</p>
                            </section>
    
                            <section>
                                <h3>10. Informacje Kontaktowe</h3>
                                <p>W sprawach związanych z niniejszą polityką prosimy o kontakt:</p>
                                <ul>
                                    <li>Email: [kontakt@beaver-plumbers.com]</li>
                                    <li>Telefon: [535551885]</li>
                                </ul>
                            </section>
                        `
                    }
                },
                sendButton: {
                    label: 'Wyślij',
                    placeholder: 'Wyślij'
                },
                orderButton: commonTranslate.plPL.action.contactButton,
            },
            action: {
                email: {
                    success: 'Email wysłany pomyślnie',
                    error: 'Błąd podczas wysyłania emaila: '
                },
                order: {
                    success: 'Zamówienie utworzone pomyślnie',
                }
            }
        },
        about: {
            title: 'O Nas',
            url: '/#about',
            content: `
            Beaver Plumbers to polska firma, która obsługuje klientów i pracowników w budownictwie, projektach budowlanych i czystej energii.
            Zbudowaliśmy lokalne profesjonalne zespoły, a nasza działalność obejmuje całą Polskę.
            `,
            learnMore: 'Dowiedz się więcej',
            cardContent: [
                {
                    title: 'Energia odnawialna',
                    content: `
                    Energia odnawialna to energia pozyskiwana ze źródeł odnawialnych,
                    które są naturalnie uzupełniane w ludzkiej skali czasowej, w tym źródła neutralne pod względem emisji dwutlenku węgla, takie jak światło słoneczne, wiatr, deszcz, pływy,
                    fale i ciepło geotermalne. Termin ten często obejmuje również biomasę.
                    `,
                    img: about_1,
                },
                {
                    title: 'Panele Słoneczne',
                    content: `
                    Termin panel słoneczny jest używany potocznie dla modułu fotowoltaicznego (PV).
                    Moduł PV to zespół ogniw fotowoltaicznych zamontowanych w ramie do instalacji.
                    Ogniwa fotowoltaiczne wykorzystują światło słoneczne jako źródło energii i wytwarzają prąd stały.
                    `,
                    img: about_2,
                },
                {
                    title: 'Projekty domów',
                    content: `
                    Plan domu to zestaw rysunków konstrukcyjnych lub roboczych (czasami nazywanych planami)
                    które określają wszystkie specyfikacje konstrukcyjne domu mieszkalnego, takie jak wymiary,
                    materiały, układy, metody i techniki instalacji. Projekty domów to jedna z naszych specjalizacji.
                    `,
                    img: about_3
                },
                {
                    title: 'Elektryczność',
                    content: `
                    Elektryczność to zespół zjawisk fizycznych związanych z obecnością i ruchem materii, która ma właściwość ładunku elektrycznego.
                    Elektryczność jest powiązana z magnetyzmem, który jest częścią zjawiska elektromagnetyzmu, opisanego równaniami Maxwella.
                    `,
                    img: about_4
                },
                {
                    title: 'Wykończenia',
                    content: `
                    Zbudowaliśmy relacje z najlepszymi, lokalnymi kontrahentami w Twojej okolicy.
                    Są w 100% licencjonowane i ubezpieczone — i będą zapewniać aktualizacje kamieni milowych podczas całej kompilacji.
                    `,
                    img: about_5
                },
                {
                    title: 'Pompa ciepła',
                    content: `
                    Doradzamy w wyborze najbardziej optymalnego źródła ciepła w Twoim domu lub firmie.
                    Czy to za pomocą folii grzewczych, które utrzymują ciepło podłogi, czy promienników podczerwieni, które są najłatwiejsze w montażu.
                    `,
                    img: about_6
                },
            ]
        },
        service: {
            title: 'Serwis',
            url: '/#service',
            content: `
            Poznaj naszą ofertę usług. Możesz łatwo wybrać czas, który odpowiada Twoim osobistym wymaganiom
            `,
            submit: 'Zatwierdź',
            timeLine: [
                {
                    title: 'Wybierz datę',
                    content: 'Wybierz odpowiedni dla siebie termin usługi',
                    icon: 'calendar',
                },
                {
                    title: 'Opisz zaistniały problem',
                    content: 'Nazwij zdarzenie serwisowe i podaj dane kontaktowe',
                    icon: 'create',
                },
                {
                    title: 'Zaakceptuj zasady',
                    content: 'Prześlij zgłoszenie serwisowe i zaakceptuj nasze zasady',
                    icon: 'check',
                },
                {
                    title: 'Zaczekaj na kontakt',
                    content: 'Weź kawę i poczekaj, aż się z Tobą skontaktujemy',
                    icon: 'call',
                },
            ]
        },
        gallery: {
            title: translateCommon.plPL.navigation.gallery.label,
            url: translateCommon.plPL.navigation.gallery.href,
            header: 'Poznaj nasze realizacje: Serwis 24/7, hydraulika i elektryka. Od zapchanego zlewu po instalacje pompy ciepła',
            headerDescription: `Zapoznaj się z najnowszymi realizacjami wykonanymi przez nas na terenie całej Polski,
                                obejmującymi <strong>instalacje</strong> <strong>pomp ciepła</strong>, <strong>ogrzewanie podłogowe</strong>, <strong>systemy grzewcze</strong>,
                                <strong>serwis 24/7</strong>, <strong>pogotowie hydrauliczne</strong>, <strong>usterki</strong>, <strong>udrażnianie rur</strong>, <strong>naprawę elektryki</strong>.
                                <strong>Starannie</strong> wyknujemy każde zlecenie, <strong>gwarantujemy niezawodność</strong> i <strong>najwyższą jakość</strong> <strong>usług</strong> oferowanych przez Beaver Plumbers Group.`,
            deliveryTime: 'Czas realizacji:',
            workArea: 'Powierzchnia:',
            galleryContent: [
                {
                    title: `Wrocław - Zatkana rura`,
                    content: '<strong>Profesjonalne przeczyszczenie rur</strong> i <strong>usuwanie zatorów</strong>',
                    textSubTitle: '<strong>Szybka i skuteczna pomoc</strong> w przypadku zapchanych rur we Wrocławiu',
                    description: `W tym projekcie przeprowadziliśmy <strong>profesjonalne czyszczenie rur</strong> w budynku biurowym we Wrocławiu. 
                                  Nasz zespół wykorzystał nowoczesne technologie do <strong>usuwania zatorów</strong>, co pozwoliło na 
                                  <strong>przywrócenie pełnej funkcjonalności</strong> instalacji hydraulicznej. Dzięki <strong>serwisowi 24/7</strong>, 
                                  klient mógł skorzystać z naszych usług natychmiast po zgłoszeniu awarii. 
                                  Specjalizujemy się w kompleksowych usługach, takich jak <strong>serwis hydrauliki</strong>, 
                                  <strong>udrażnianie rur</strong>, a także <strong>czyszczenie odpływów</strong>. W tym przypadku udało się 
                                  również wykryć potencjalne problemy w instalacji i zaproponować działania zapobiegawcze, aby uniknąć 
                                  przyszłych awarii. Dzięki naszym usługom, biuro we Wrocławiu odzyskało pełną sprawność systemu, 
                                  zapewniając komfort pracy dla wszystkich użytkowników.
                    `,
                    alt: `Przeczyszczenie rur we Wrocławiu - Usuwanie zatorów, przeczyszczanie odpływów i udrażnianie rur. 
                          Profesjonalny serwis hydrauliczny w budynku biurowym, zapewniający płynne działanie instalacji.
                          Udrażnianie rur, przeczyszczenie odpływu i usuwanie blokad w instalacjach. Kompleksowe czyszczenie rur w budynkach biurowych.`,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g2/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g2/2.jpg`,
                    ],
                    deliveryTime: '1 godzina',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `10 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Warszawa - Pompa ciepła',
                    content: '<strong>Nowoczesna instalacja pompy ciepła</strong> dla <strong>oszczędności energii</strong>',
                    textSubTitle: '<strong>Efektywne ogrzewanie</strong> i <strong>chłodzenie</strong> domu jednorodzinnego w Warszawie',
                    description: `W tym projekcie zainstalowaliśmy <strong>nowoczesną pompę ciepła powietrze-woda</strong>, która zapewnia 
                                  <strong>energooszczędne ogrzewanie i chłodzenie</strong> w domu jednorodzinnym w Warszawie. 
                                  System został <strong>zintegrowany z istniejącą instalacją grzewczą</strong>, co zapewniło 
                                  <strong>komfort termiczny przez cały rok</strong> i <strong>znaczną oszczędność energii</strong>. 
                                  To rozwiązanie jest idealne dla właścicieli domów, którzy chcą obniżyć koszty energii i zmniejszyć swój ślad węglowy.
                    `,
                    alt: `Instalacja pompy ciepła w Warszawie - Nowoczesny system powietrze-woda zapewniający efektywność energetyczną, 
                          komfort termiczny oraz całoroczne ogrzewanie i chłodzenie dla domów jednorodzinnych.`,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g1/0.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/2.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/3.jpg`,
                    ],
                    deliveryTime: '2 tygodnie',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `25 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Szczecin - Awaria systemu grzewczego',
                    content: '<strong>Naprawa systemu grzewczego</strong> i <strong>serwis hydrauliki</strong>',
                    textSubTitle: '<strong>Szybka pomoc</strong> w usuwaniu usterek systemów grzewczych w Szczecinie',
                    description: `W tym projekcie zajęliśmy się <strong>naprawą systemu grzewczego</strong> w hotelu w Szczecinie. 
                                  Nasz zespół przeprowadził szczegółową diagnozę i usunął usterki w instalacji hydraulicznej, co pozwoliło na 
                                  <strong>przywrócenie pełnej sprawności</strong> systemu. Dzięki naszemu <strong>serwisowi 24/7</strong>, 
                                  hotel mógł szybko rozwiązać problem bez wpływu na komfort gości. Oferujemy kompleksowe usługi, takie jak 
                                  <strong>serwis hydrauliki</strong>, <strong>czyszczenie rur</strong>, oraz <strong>konserwacja systemów grzewczych</strong>. 
                                  Nasze działania obejmowały również wdrożenie usprawnień w systemie, które pomogą zminimalizować ryzyko awarii w przyszłości.`,
                    alt: `Naprawa systemu grzewczego w Szczecinie - Usuwanie usterek w instalacji hydraulicznej, serwis systemów grzewczych oraz 
                          szybka reakcja dzięki serwisowi 24/7. Profesjonalna naprawa i konserwacja w hotelu.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g3/0.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g3/1.jpg`,
                    ],
                    deliveryTime: '3 dni',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `30 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wałbrzych - Awaria ogrzewania podłogowego',
                    content: '<strong>Naprawa ogrzewania podłogowego</strong> i <strong>serwis systemu grzewczego</strong>',
                    textSubTitle: '<strong>Szybka pomoc</strong> w usuwaniu problemów z ogrzewaniem podłogowym w Wałbrzychu',
                    description: `W tym projekcie zajęliśmy się <strong>naprawą systemu ogrzewania podłogowego</strong> w hali produkcyjnej w Wałbrzychu. 
                                  Nasz zespół przeprowadził dokładną diagnozę systemu zasilanego pompą ciepła powietrze-woda, 
                                  identyfikując przyczynę problemu. Dzięki naszemu <strong>serwisowi hydrauliki</strong> oraz 
                                  <strong>serwisowi 24/7</strong>, usterka została szybko usunięta, a system odzyskał pełną sprawność. 
                                  Specjalizujemy się w kompleksowych usługach, takich jak <strong>naprawa ogrzewania podłogowego</strong>, 
                                  <strong>usuwanie wycieków</strong> oraz <strong>czyszczenie instalacji hydraulicznych</strong>. 
                                  Wdrożyliśmy również działania zapobiegawcze, aby zminimalizować ryzyko podobnych problemów w przyszłości, 
                                  co pozwala na optymalizację kosztów i zapewnienie komfortu pracy pracowników.
                    `,
                    alt: `Naprawa ogrzewania podłogowego w Wałbrzychu - Usuwanie usterek w systemach zasilanych pompą ciepła, serwis 24/7, 
                          konserwacja instalacji hydraulicznych oraz optymalizacja działania systemu grzewczego.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g4/11.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g4/12.jpg`,
                    ],
                    deliveryTime: '5 dni',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `70 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdańsk - Serwis pompy ciepła',
                    content: '<strong>Naprawa i konserwacja pomp ciepła</strong> w budynkach publicznych',
                    textSubTitle: '<strong>Szybka i niezawodna pomoc</strong> w serwisowaniu pomp ciepła w Gdańsku',
                    description: `W tym projekcie zajęliśmy się <strong>serwisem pompy ciepła</strong> w budynku użyteczności publicznej w Gdańsku. 
                                  Nasz zespół przeprowadził dokładną diagnozę oraz wykonaliśmy <strong>konserwację i naprawę</strong> systemu, 
                                  co pozwoliło na przywrócenie pełnej sprawności urządzenia. Dzięki naszemu <strong>serwisowi 24/7</strong>, 
                                  usługa została wykonana szybko i bez przestojów, zapewniając ciągłość pracy w obiekcie. 
                                  Specjalizujemy się w kompleksowych usługach, takich jak <strong>serwis pomp ciepła</strong>, 
                                  <strong>konserwacja instalacji hydraulicznych</strong> oraz <strong>rozwiązywanie problemów z wydajnością systemu</strong>. 
                                  Nasze działania obejmowały także usprawnienia systemu, które poprawiły jego efektywność i zredukowały koszty energii.
                    `,
                    alt: `Serwis pompy ciepła w Gdańsku - Konserwacja i naprawa systemów grzewczych, diagnostyka, 
                          serwis hydrauliki oraz optymalizacja działania pomp ciepła w budynkach publicznych.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g5/14.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '3 dni',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdynia - Serwis pompy ciepła w hotelu',
                    content: '<strong>Naprawa i konserwacja pomp ciepła</strong> dla obiektów hotelowych',
                    textSubTitle: '<strong>Szybka i profesjonalna pomoc</strong> w serwisie pomp ciepła w Gdyni',
                    description: `W tym projekcie zajęliśmy się <strong>serwisem pompy ciepła</strong> w hotelu w Gdyni. 
                                  Nasz zespół przeprowadził szczegółową diagnostykę oraz wykonał <strong>naprawę i konserwację</strong> systemu, 
                                  co pozwoliło na przywrócenie pełnej funkcjonalności urządzenia. Dzięki naszym usługom, hotel ponownie zapewnia 
                                  gościom możliwość <strong>niezależnego sterowania temperaturą</strong> w pokojach, przy jednoczesnym 
                                  <strong>obniżeniu kosztów energii</strong>. Specjalizujemy się w kompleksowych usługach, takich jak 
                                  <strong>serwis pomp ciepła</strong>, <strong>konserwacja instalacji hydraulicznych</strong>, 
                                  a także <strong>optymalizacja wydajności systemu grzewczego</strong>. Nasze działania obejmowały również 
                                  wdrożenie usprawnień systemu, które zmniejszyły koszty operacyjne hotelu.
                    `,
                    alt: `Serwis pompy ciepła w Gdyni - Naprawa i konserwacja systemów grzewczych w hotelach, 
                          diagnostyka pomp ciepła, optymalizacja wydajności oraz profesjonalne usługi hydrauliczne.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '4 dni',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `40 m<sup>2</sup>`}}/>,
                },
            ]
        },
        offer: {
            title: translateCommon.plPL.navigation.offer.label,
            content: 'Poznaj naszą ofertę',
            url: commonTranslate.plPL.navigation.offer.href,
            contactButton: commonTranslate.plPL.action.contactButton,
            actions: {
                title: 'ZAMÓW WYBRANE USŁUGI Z NASZEJ OFERTY',
                description: `Zapoznaj się z naszą szeroką ofertą, obejmującą <strong>profesjonalny serwis 24/7</strong>, 
                              <strong>instalacją paneli słonecznych</strong>, <strong>montażem pomp ciepła</strong> 
                              oraz <strong>nowoczesnego ogrzewania podłogowego</strong>. 
                              Nasze rozwiązania są zaprojektowane z myślą o <strong>efektywności energetycznej</strong>, 
                              <strong>obniżeniu kosztów eksploatacji</strong>, oraz <strong>komfortowym użytkowaniu na co dzień</strong>. 
                              Sprawdź, jak nasze usługi mogą zwiększyć wartość Twojego domu i poprawić jego energooszczędność.`,
                buttons: {
                    SERVICE_24_7: {
                        action: `SERVICE_24_7`,
                        name: `Serwis 24/7`,
                        icon: <Construction/>,
                        image: `/assets/building_insulation.png`,
                    },
                    SOLAR_PANELS: {
                        action: `SOLAR_PANELS`,
                        name: `Panele słoneczne`,
                        icon: <SolarPowerIcon/>,
                        image: `/assets/solar_panel.png`,
                    },
                    HEAT_PUMP: {
                        action: `HEAT_PUMP`,
                        name: `Pompa ciepła`,
                        icon: <HeatPumpIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    UNDERFLOOR_HEATING: {
                        action: `UNDERFLOOR_HEATING`,
                        name: `Ogrzewanie podłogowe`,
                        icon: <CabinIcon/>,
                        image: `/assets/floor_heating.png`,
                    }
                },
                content: {
                    title: 'POGLĄDOWA ANALIZA KOSZTÓW DLA POMPY CIEPŁA W 2024 ROKU',
                    elements: {
                        SOLAR_PANELS: {
                            title: `Najlepsze Panele Słoneczne - Optymalne Rozwiązanie Energetyczne`,
                            titleDescription: `Panele słoneczne to innowacyjny sposób na zasilanie Twojego domu lub firmy, oferując 
                                               <strong>ekonomiczne rozwiązania energii słonecznej</strong>, 
                                               jednocześnie redukując koszty energii i minimalizując wpływ na środowisko. 
                                               <strong>Kluczowe Zalety Paneli Słonecznych:</strong>`,
                            advantages: [
                                `Znaczące obniżenie rachunków za energię elektryczną.`,
                                `Przyjazne dla środowiska i redukują ślad węglowy.`,
                                `Niskie koszty utrzymania i długa żywotność.`,
                                `Zwiększenie wartości nieruchomości.`,
                                `Możliwość skorzystania z ulg podatkowych i dotacji.`
                            ],
                            subtitle: `Pokonywanie Wyzwań Instalacji Paneli Słonecznych`,
                            subtitleDescription: `Choć panele słoneczne są doskonałym wyborem, ważne jest uwzględnienie takich czynników jak 
                                                  <strong>wysokie koszty początkowe</strong>, <strong>zależność od światła słonecznego</strong> 
                                                  oraz <strong>wymagana przestrzeń instalacyjna</strong>. 
                                                  Jednakże, oferujemy rozwiązania, które pomogą pokonać te wyzwania:`,
                            disadvantagesSolutions: [
                                `Elastyczne opcje finansowania, aby zniwelować koszty początkowe.`,
                                `Profesjonalny montaż zapewniający optymalną wydajność nawet przy ograniczonym nasłonecznieniu.`,
                                `Indywidualne projekty instalacji dostosowane do dostępnej przestrzeni.`
                            ]
                        },
                        BUILDING_INSULATION: {
                            title: `Izolacja Budynków`,
                            titleDescription: ``,
                            advantages: [
                                ``
                            ],
                            subtitle: `Pokonywanie Wyzwań Instalacji Izolacji Budynków`,
                            subtitleDescription: ``,
                            disadvantagesSolutions: [
                                ``
                            ]
                        },
                        HEAT_PUMP: {
                            action: `HEAT_PUMP`,
                            name: `Pompa Ciepła`,
                            icon: `<HeatPumpIcon/>`,
                            image: `/assets/heat_pump.png`
                        },
                        UNDERFLOOR_HEATING: {
                            title: `Efektywne Ogrzewanie Podłogowe - Maksymalny Komfort i Oszczędność Energii`,
                            titleDescription: `Ogrzewanie podłogowe to <strong>nowoczesne i wydajne rozwiązanie grzewcze</strong>, 
                                               które zapewnia równomierne rozprowadzanie ciepła w całym domu lub biurze. 
                                               <strong>Najważniejsze Zalety Ogrzewania Podłogowego:</strong>`,
                            advantages: [
                                `Równomierne rozprowadzanie ciepła po całym pomieszczeniu, co zwiększa komfort użytkowania i efektywność energetyczną.`,
                                `Niewidoczna instalacja pozwala na maksymalne wykorzystanie przestrzeni użytkowej.`,
                                `Kompatybilność z różnymi źródłami energii, takimi jak pompy ciepła i panele słoneczne.`,
                                `Redukcja kurzu i alergenów w porównaniu z tradycyjnymi grzejnikami.`,
                                `Cicha praca bez hałasu generowanego przez urządzenia grzewcze.`,
                            ],
                            subtitle: `Pokonywanie Wyzwań Instalacji Ogrzewania Podłogowego`,
                            subtitleDescription: `Mimo licznych zalet ogrzewania podłogowego, niektóre wyzwania, takie jak 
                                                  <strong>wysokie koszty początkowe</strong>, <strong>czasochłonny montaż</strong> 
                                                  oraz <strong>trudności z modernizacją istniejącej podłogi</strong>, mogą stanowić przeszkodę. 
                                                  Oto jak możemy pomóc w ich rozwiązaniu:`,
                            disadvantagesSolutions: [
                                `Elastyczne plany finansowe, które ułatwiają inwestycję w nowoczesne ogrzewanie.`,
                                `Profesjonalny zespół instalacyjny zapewnia szybki i sprawny montaż.`,
                                `Indywidualne projekty dostosowane do warunków technicznych i potrzeb użytkownika.`,
                            ],
                        }

                    },
                    serviceOffer: {
                        orderAction: `${commonTranslate.plPL.action.check}`,
                        title: `Profesjonalne usługi hydrauliczne i elektryczne - serwis 24/7`,
                        description: `Oferujemy <strong>kompleksowy zakres profesjonalnych usług hydraulicznych, grzewczych oraz szeroko pojętej elektryki</strong>, w tym 
                                      <strong>całodobową obsługę awaryjną</strong>, <strong>serwis 24 godziny</strong>, <strong>czyszczenie i udrażnianie rur</strong>, 
                                      <strong>wykrywanie wycieków</strong>. <strong>Naprawimy usterki i awarie</strong>. Nasze rozwiązania są dostosowane zarówno do 
                                      <strong>nieruchomości mieszkalnych, jak i komercyjnych</strong>, zapewniając <strong>niezawodność i opłacalność</strong>. 
                                      Odkryj nasze kategorie, które ułatwią wybór odpowiednich usług.`,
                    },
                    data: [
                        {
                            title: 'Czas realizacji',
                            icon: <QueryBuilderIcon fontSize={"inherit"}/>,
                            description: '2 tygodnie'
                        },
                        {
                            title: 'Powierzchnia',
                            icon: <HomeIcon fontSize={"inherit"}/>,
                            description: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>
                        },
                        {
                            title: 'Okres grzewczy',
                            icon: <CalendarMonthIcon fontSize={"inherit"}/>,
                            description: '1 miesiąc',
                        }
                    ],
                    table: {
                        tableTitle: "Tabela kosztów",
                        tableHeader: [
                            "Rodzaj paliwa",
                            "Powierzchnia użytkowa",
                            "Wartość (jednostka)",
                            "Cena (zł/miesiąc)"
                        ],
                        fuelVariants: [
                            {
                                icon: <HeatPumpIcon/>,
                                fuelName: "Pompa ciepła",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "wartość",
                                price: 120
                            },
                            {
                                icon: <ForestIcon/>,
                                fuelName: "Drewno",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "0.6 t",
                                price: 400
                            },
                            {
                                icon: <OutdoorGrillIcon/>,
                                fuelName: "Węgiel",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "100 kg",
                                price: 300
                            },
                            {
                                icon: <PropaneTankIcon/>,
                                fuelName: "Gaz",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: <span dangerouslySetInnerHTML={{__html: `70 m<sup>3</sup>`}}/>,
                                price: 250
                            },
                            {
                                icon: <FlashOnIcon/>,
                                fuelName: "Prąd",
                                surfaceArea: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                                value: "180 kWh",
                                price: 360
                            }
                        ]
                    }
                },
                summary: `Poniżej przedstawiamy szczegółowe wyliczenia kosztów dla domu jednorodzinnego o powierzchni użytkowej 50&nbsp;m<sup>2</sup>, 
                          uwzględniając <strong>aktualne ceny energii</strong> oraz parametry na rok 2024. 
                          Aby dowiedzieć się więcej o <strong>efektywnym ogrzewaniu</strong> i <strong>obniżeniu kosztów eksploatacyjnych</strong> 
                          z zastosowaniem <strong>pomp ciepła</strong>, skontaktuj się z nami już dziś. 
                          Oferujemy <strong>indywidualne doradztwo</strong> oraz <strong>nowoczesne rozwiązania grzewcze</strong> 
                          dostosowane do Twoich potrzeb.`,
                noAction: 'Brak dostępnych akcji. Skontaktuj się z nami.'
            },
        },
        footer: {
            products: {
                title: 'Produkty',
                solarPanel: {
                    title: 'Panele słoneczne',
                    //TODO: make it generic, it appears in few places
                    url: commonTranslate.plPL.navigation.offer.href,
                },
                buildingInsulation: {
                    title: 'Ocieplenie budynku',
                    url: commonTranslate.plPL.navigation.offer.href,
                },
                heatPump: {
                    title: 'Pompy ciepła',
                    url: commonTranslate.plPL.navigation.offer.href,
                },
                floorHeating: {
                    title: 'Ogrzewanie podłogowe',
                    url: '/#construction-elements',
                },
            },
            information: {
                title: 'Informacja',
                privacyPolicy: {
                    title: 'Polityka prywatności',
                    url: '#',
                },
                license: {
                    title: 'Umowy i licencje',
                    url: '#',
                },
                faq: {
                    title: 'FAQ',
                    url: '#',
                },
            },
            about: {
                title: 'O nas',
            },
            subscribe: {
                label: 'Subskrypcja',
            },
            address: {
                label: 'ul. Rynek 1, 00-000, Wrocław - Beaver Plumbers',
            },
            termsAndConditions: {
                label: 'Zasady & Warunki'
            },
            privacyPolicy: {
                label: 'Polityka prywatności'
            },
            sitemap: {
                label: 'Mapa strony'
            },
            designedBy: {
                label: 'Opracowano przez Maksim Halushka dla Beaver Plumbers'
            },
            contactUs: {
                label: 'Skontaktuj się z nami'
            }
        },
        banner: {
            info: 'Strona jest w procesie tworzenia. Prosimy o kontakt:',
            phone: commonTranslate.plPL.phone,
        },
        technician: {
            label: 'Serwisant',
            url: '/technician',
            shortInfo: 'Profil serwisanta',
            token: {
                action: {
                    show: `${commonTranslate.plPL.action.show}`,
                    close: `${commonTranslate.plPL.action.close}`,
                }
            },
            action: {
                home: `${commonTranslate.plPL.action.home}`,
                logOut: `${commonTranslate.plPL.action.logOut}`,
                logIn: `${commonTranslate.plPL.action.logIn}`,
            }
        }
    }
}

export default translate;
