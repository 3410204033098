import React from "react";
import {styled} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {classes} from "./construction-elements-actions-style"
import {Typography, useMediaQuery} from "@mui/material";
import Fab from '@mui/material/Fab';
import {AppContext} from "../../../context/app-context/app-context";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const StyledConstructionElementsActions = styled(Box)(({theme}) => ({
    paddingRight: '8vw',
    minHeight: '30vh',
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 20,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: '8vw',
    },

    [`& .${classes.actionsWrapper}`]: {
        flexDirection: "row",
        minHeight: 'inherit',
    },
    [`& .${classes.actionsTitleAndDescription}`]: {
        display: "flex",
        alignItems: 'center',
    },
    [`& .${classes.actionsTitle}`]: {
        color: theme.palette.primary.background,
        minWidth: '100%',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 0',
        },
    },
    [`& .${classes.actionsDescription}`]: {
        color: theme.palette.primary.background,
    },
    [`& .${classes.actionsButtons}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    [`& .${classes.actionsButtonsElement}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        textAlign: 'center',
        color: theme.palette.primary.background,
    },
    [`& .${classes.actionsButtonsElementIcon}`]: {
        marginTop: 10,
        marginBottom: 10,
    },
    [`& .${classes.actionsButtonsElementText}`]: {
        color: theme.palette.primary.background,
        fontSize: "0.75rem",
        textAlign: 'center',
        fontWeight: "bold",
        letterSpacing: "1px",
        textTransform: "uppercase",
        [theme.breakpoints.up('xl')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
    },
}));

function ConstructionElementsActions({actionButtons, selectConstructionElement}) {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const {setSelectedConstructionElement} = React.useContext(AppContext);
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedOffer = translation.offer;

    return (
        <StyledConstructionElementsActions>
            <Grid container
                  className={classes.actionsWrapper}
            >
                <Grid item
                      container
                      className={classes.actionsTitleAndDescription}
                >
                    <Typography variant={"h2"} className={classes.actionsTitle}>
                        {translatedOffer.actions.title}
                    </Typography>
                    <Typography variant="paragraph"
                                component="p"
                                gutterBottom
                                className={classes.actionsDescription}
                                dangerouslySetInnerHTML={{__html: translatedOffer.actions.description}}/>
                </Grid>
                <Grid item
                      container
                      className={classes.actionsButtons}
                >
                    {Object.keys(actionButtons).map(key => {
                        const actionButton = actionButtons[key];
                        return (
                            <Grid item
                                  key={key}
                                  xs={isSmallScreen ? 6 : 3}
                                  className={classes.actionsButtonsElement}
                            >
                                <Fab color="primary"
                                     aria-label="edit"
                                     className={classes.actionsButtonsElementIcon}
                                     onClick={() => {
                                         setSelectedConstructionElement(-1);
                                         selectConstructionElement(actionButton);
                                     }}
                                >
                                    {actionButton.icon}
                                </Fab>
                                <Typography variant="paragraph" component={"p"}
                                            className={classes.actionsButtonsElementText}>
                                    <strong>{actionButton.name}</strong>
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </StyledConstructionElementsActions>
    );
}

export default ConstructionElementsActions;
