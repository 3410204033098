import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {IconButton, useMediaQuery} from '@mui/material';
import {Menu} from '@mui/icons-material';
import {classes} from './home-navigation-styles';
import HomeNavigationMenuDrawer from "./home-navigation-menu-drawer/home-navigation-menu-drawer";
import HomeNavigationNavbar from "./home-navigation-navbar/home-navigation-navbar";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const StyledHomeNavigation = styled('header')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '100%',

    [`& .${classes.mobileMenuButton}`]: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(2),
        borderRadius: '8px',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.background,
        },
    },
}));

const HomeNavigation = () => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];

    const [navLinks] = useState([
        {href: translation.offer.url, label: translation.offer.title},
        {href: translation.gallery.url, label: translation.gallery.title},
        {href: translation.contact.url, label: translation.contact.label},
    ]);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <StyledHomeNavigation>
            {!isSmallScreen ? (
                <HomeNavigationNavbar navLinks={navLinks}/>
            ) : (
                <>
                    <IconButton
                        className={classes.mobileMenuButton}
                        aria-label="Additional mobile menu"
                        onClick={toggleDrawer(true)}
                    >
                        <Menu/>
                    </IconButton>
                    <HomeNavigationMenuDrawer navLinks={navLinks} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}/>
                </>
            )}
        </StyledHomeNavigation>
    )
};

export default HomeNavigation;
