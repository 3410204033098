import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {classes} from "./contact-dialog-styles"
import {DialogContext} from "../../../context/dialog-context/dialog-context";
import {Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import CancelIcon from "@mui/icons-material/Cancel";
import {AppContext} from "../../../context/app-context/app-context";
import {colorPalette} from "../../../util/color-palette";
import {useContactApi} from "../api/contact-api";
import ContactPersonalData from "../contact-personal-data/contact-personal-data";
import Box from "@mui/material/Box";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import ContactButton from "../contact-button/contact-button";
import ContactInputPrivacyPolicy from "../contact-input-privacy-policy/contact-input-privacy-policy";
import type {ContactDataValid} from "../shared/dto/contact-data-valid";
import type {PersonalDataValid} from "../shared/dto/personal-data-valid";

const ContactDialogStyled = styled(Dialog)(({theme}) => ({
    [`& .${classes.form}`]: {
        display: 'contents'
    },
    [`& .${classes.cancelIcon}`]: {
        padding: 10,
        paddingRight: 25,
        fontSize: '2rem',
        color: colorPalette.main,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    [`& .${classes.titleWrapper}`]: {
        backgroundColor: colorPalette.base
    },
    [`& .${classes.titleContent}`]: {
        maxHeight: 50,
        overflowY: 'auto',
        paddingBottom: 10,
        width: '100%',
        color: colorPalette.main,
        textAlign: 'center',
        fontSize: "0.75rem",
        fontWeight: "bold",
        letterSpacing: "1px",
        textTransform: "uppercase",
        lineHeight: 1.6
    }

}))

const ContactDialog = () => {

    const {sendEmail} = useContactApi();
    const {visible, setVisible} = useContext(DialogContext);
    const {setInfo} = useContext(AppContext);
    const {localeContext} = useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;
    const [isFormValid, setIsFormValid] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

    const [contact: PersonalDataValid, onContactChange] = useState({
        personalData: {
            name: {value: '', validity: {required: true, maxLength: 100}},
            email: {value: '', validity: {required: true, pattern: "^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$"}},
            phoneNumber: {value: '', validity: {required: true, pattern: "^(\\+48)?\\s?\\d{3}\\s?\\d{3}\\s?\\d{3}$"}},
            message: {value: '', validity: {required: true, maxLength: 500}},
        }
    });

    useEffect(() => {
        const fieldsToValidate = Object.values(contact.personalData);

        const hasErrors = fieldsToValidate.some(field =>
            (field.validity.required && !field.value) ||
            (field.validity.pattern && !new RegExp(field.validity.pattern).test(field.value)) ||
            (field.validity.maxLength && field.value.length > field.validity.maxLength)
        );
        const allEmpty = fieldsToValidate.every(field => field.validity.required && !field.value);
        setIsFormValid(!hasErrors && !allEmpty);
    }, [contact]);

    const onClose = () => {
        setVisible(false);
    }

    const transformToContactData = (validContact) => {
        return {
            personalData: Object.fromEntries(
                Object.entries(validContact.personalData).map(([key, {value}]) => [key, value])
            )
        };
    };

    const submit = (event) => {
        event.preventDefault();
        const contactData = transformToContactData(contact);
        if (contact) {
            sendEmail(contactData).then(() => (setInfo(translatedContact.action.email.success)));
        } else {
            setInfo(translatedContact.action.email.error);
        }
        setVisible(false);
    }

    return (
        <ContactDialogStyled open={visible}
                             onClose={onClose}
                             maxWidth={'md'}
                             fullWidth={true}
        >
            <CancelIcon className={classes.cancelIcon}
                        onClick={onClose}
            />
            <DialogTitle className={classes.titleWrapper}>
                <Typography className={classes.titleContent}>
                    {translatedContact.label}
                </Typography>
            </DialogTitle>
            <DialogContent style={{backgroundColor: colorPalette.base}}>
                <Box component={'form'}
                     className={classes.form}
                     noValidate
                     autoComplete="off"
                     onSubmit={submit}
                >
                    <ContactPersonalData contact={contact}
                                         onContactChange={onContactChange}
                    />
                    <ContactButton id={'send-button'}
                                   label={translatedContact.form.sendButton.label}
                                   disabled={!isFormValid || !privacyPolicyAccepted}
                    />
                    <ContactInputPrivacyPolicy id={'privacy-policy-accepted'}
                                               privacyPolicyAccepted={privacyPolicyAccepted}
                                               setPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
                    />
                </Box>
            </DialogContent>
        </ContactDialogStyled>
    );
}

export default ContactDialog;
